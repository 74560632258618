import React from "react";
import { Helmet } from "react-helmet";

import Footer from "../Static/Footer";
import Header from "../Static/Header";
import PageTitle from "./Elements/PageTitle";

class PersonalTravelStart extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  frameContent = () => {
    const iframe = {
      __html:
        '<iframe class="scheduler_frame" src="https://stcontactform.carrd.co" width="100%" height="550"></iframe>'
    };
    return iframe;
  };

  render() {
    return (
      <div className="pageframe personal overview">
        <Header />

        <Helmet>
          {/* Generic  */}
          <title>Get Started - Simple Trips Personal Travel</title>
          <meta
            name="description"
            content="Give Simple Trips a try. It's easy to get started. There's no cost or obligation."
          />

          {/* Twitter */}
          <meta
            name="twitter:card"
            value="Give Simple Trips a try. It's easy to get started. There's no cost or obligation."
          />

          {/* Open Graph */}
          <meta
            property="og:title"
            content="Get Started - Simple Trips Personal Travel"
          />
          <meta property="og:type" content="article" />
          <meta
            property="og:url"
            content="https://simpletrips.com/personal-travel/start"
          />
          <meta
            property="og:image"
            content="https://simpletrips.com/assets/images/meta.png"
          />
          <meta
            property="og:description"
            content="Give Simple Trips a try. It's easy to get started. There's no cost or obligation."
          />
        </Helmet>

        <PageTitle
          title="Give it a try."
          subtitle="It's easy to get started. There's no cost or obligation. "
        />

        <div className="personaldemo">
          <div dangerouslySetInnerHTML={this.frameContent()} />
        </div>

        <Footer />
      </div>
    );
  }
}

export default PersonalTravelStart;
