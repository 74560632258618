import React from "react";
import axios from "axios";
import HotelDetailTripExpertReviewItem from "./HotelDetailTripExpertReviewItem";

class HotelDetailTripExpert extends React.Component {
  state = {
    hotel: null
  };

  componentWillMount() {
    if (this.props.hotel.idTripExpert) {
      this.fetchDetailedInfo(this.props.hotel.idTripExpert);
    }
  }

  fetchDetailedInfo = id => {
    var hotelUrl =
      "https://us-central1-simple-trips.cloudfunctions.net/tripExpertVenueDetails" +
      "?api_key=50295020f3bb8aeaf70eabe370603e4c" +
      "&venue_id=" +
      id;

    axios.get(hotelUrl).then(result => {
      this.setState({ hotel: result.data.response.venues[0] });
    });
  };

  render() {
    return (
      <div className="databucket expertreviews">
        {this.state.hotel == null ? (
          "loading"
        ) : (
          <div>
            <h4>Expert Reviews</h4>
            {this.state.hotel.reviews.map((review, index) => {
              return (
                <HotelDetailTripExpertReviewItem key={index} review={review} />
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

export default HotelDetailTripExpert;
