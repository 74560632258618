import React from "react";
import TopBar from "./TopBar";
import { signOut } from "../../helpers/auth";
import base, { firebaseApp } from "../../base";
import LoadingSimple from "../Static/LoadingSimple";
import AdminJump from "./AdminJump";

class Settings extends React.Component {
  state = {
    loading: true,
    user: null
  };

  componentWillMount() {
    base.listenToDoc("users/" + firebaseApp.auth().currentUser.uid, {
      context: this,
      then(data) {
        this.setState({ user: data, loading: false });
      },
      onFailure(err) {
        //handle error
      }
    });
  }

  logout = () => {
    signOut();
  };

  render() {
    return (
      <div className="app-page settings">
        <TopBar />
        <div className="main-window">
          <div className="container">
            <h2>Settings</h2>
            {this.state.loading ? (
              <div className="spacing">
                <LoadingSimple />
              </div>
            ) : (
              <div>
                <p>{this.state.user.email}</p>
                <AdminJump />
                <button className="button" onClick={this.logout}>
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Settings;
