import React from "react";
import base from "../../base";
import Navbar from "./Navbar";
import HotelDetailEAN from "./HotelDetailEAN";
import HotelDetailTripExpert from "./HotelDetailTripExpert";

class HotelDetail extends React.Component {
  state = {
    hotel: null,
    loading: true
  };

  componentWillMount() {
    this.getDetails();
  }

  openExpedia = () => {
    const url =
      "https://www.expedia.com/Hotel-Search?selected=" +
      this.state.hotel.idExpedia;
    window.open(url);
  };

  openEan = () => {
    const url =
      "https://hotelbook.simpletrips.com/hotel/" + this.state.hotel.idEan;
    window.open(url);
  };

  openTravelPayouts = () => {
    const url =
      "http://affiliatebookings.simpletrips.com/hotels?hotelId=" +
      this.state.hotel.idTravelPayouts;
    window.open(url);
  };

  openHotelWebsite = () => {
    window.open(this.state.hotel.website);
  };

  openPublicSTLink = () => {
    window.open("/hotels/" + this.props.match.params.slug);
  };

  getDetails = () => {
    base
      .get("hotels", {
        context: this,
        withIds: true,
        query: ref => ref.where("slug", "==", this.props.match.params.slug)
      })
      .then(data => {
        this.setState({ hotel: data[0], loading: false });
        document.title = this.state.hotel.name + " - Simple Trips";
      })
      .catch(err => {
        //handle error
      });
  };

  render() {
    return (
      <div className="management">
        <Navbar hideLinks={true} />
        <div className="mainpanel">
          {this.state.loading ? (
            "..."
          ) : (
            <div className="hoteldetail">
              <h1>{this.state.hotel.name}</h1>
              <div className="hotelactions">
                <button
                  onClick={() => {
                    this.openEan();
                  }}
                  className="general"
                >
                  EAN
                </button>
                <button
                  onClick={() => {
                    this.openExpedia();
                  }}
                  className="general"
                >
                  Expedia
                </button>
                <button
                  onClick={() => {
                    this.openTravelPayouts();
                  }}
                  className="general"
                >
                  Affiliate
                </button>
                <button
                  onClick={() => {
                    this.openHotelWebsite();
                  }}
                  className="general"
                >
                  Hotel Site
                </button>
                <button
                  onClick={() => {
                    this.openPublicSTLink();
                  }}
                  className="general"
                >
                  Public Page
                </button>
              </div>
              {/* end actions */}

              <div className="databucket basicinfo">
                <p className="detail">{this.state.hotel.address}</p>
                <p className="detail">{this.state.hotel.phone}</p>
                <p className="detail">
                  {this.state.hotel.starRating} star hotel
                </p>
              </div>
              {/* end basic info */}

              <HotelDetailTripExpert hotel={this.state.hotel} />

              <div className="databucket googlereviews">
                <h4>Google Reviews</h4>
                <p className="detail">coming soon</p>
              </div>
              {/* end google reviews */}

              <HotelDetailEAN hotel={this.state.hotel} />
            </div> // end outer container {/* */}
          )}
        </div>
      </div>
    );
  }
}

export default HotelDetail;
