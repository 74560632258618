import React from "react";

class GooglePlaceIdPickerItem extends React.Component {
  render() {
    return (
      <div>
        <div>
          {this.props.pickerCallback ? (
            <button
              className="choose"
              onClick={() => {
                this.props.pickerCallback(this.props.suggestion);
              }}
            >
              pick
            </button>
          ) : (
            ""
          )}
          {this.props.suggestion.description}
          <br />
          <br />
          <br />
        </div>
      </div>
    );
  }
}

export default GooglePlaceIdPickerItem;
