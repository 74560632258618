import React from "react";
import { Link } from "react-router-dom";
import TimeAgo from "react-timeago";

class RequestListItem extends React.Component {
  render() {
    return (
      <div>
        <div className="requestlistitem">
          <div className="requestdescription">
            {this.props.request.description}
          </div>

          <div className="requesttimeago">
            Submitted{" "}
            <TimeAgo date={this.props.request.createdAt} minPeriod={30} />
          </div>

          <div className="requestlinkcontainer">
            <Link to={"r/" + this.props.request.id} className="requestlink">
              View <i className="fas fa-angle-right" />
            </Link>
          </div>

          <div className="clear" />
        </div>
      </div>
    );
  }
}

export default RequestListItem;
