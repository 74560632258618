import React from "react";
import Loading from "../../Static/LoadingSimple";
import BlogRecentItem from "./BlogRecentItem";
import axios from "axios";
import parser from "rss-parser-browser";

class BlogRecent extends React.Component {
  state = {
    items: null
  };

  componentDidMount() {
    axios
      .get("https://us-central1-simple-trips.cloudfunctions.net/fetchBlogPosts")
      .then(result => {
        parser.parseString(result.data, (err, feed) => {
          if (err == null) {
            this.setState({ items: feed.feed.entries });
          } else {
            this.setState({
              items: [
                {
                  title: "Visit our blog.",
                  link: "https://blog.simpletrips.com/"
                }
              ]
            });
          }
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          items: [
            {
              title: "Visit our blog.",
              link: "https://blog.simpletrips.com/"
            }
          ]
        });
      });
  }

  render() {
    return (
      <div className="bloggrid">
        <div className="wrapper">
          <div className="bloggridinner">
            <h4 className="styled">From the blog</h4>
            {this.state.items == null ? (
              <div>
                <Loading />
              </div>
            ) : (
              <div>
                {Object.keys(this.state.items).map(key => (
                  <div key={key}>
                    <BlogRecentItem item={this.state.items[key]} />
                    {/* Throw a clear every 3  */}
                    {key % 3 === 2 ? <div className="clear" /> : ""}
                  </div>
                ))}
                <div className="clear" />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default BlogRecent;
