import React from "react";
import axios from "axios";
import HotelGoogleReviewsItem from "./HotelGoogleReviewsItem";

class HotelGoogleReviews extends React.Component {
  state = {
    reviews: []
  };

  fetchGoogleDetails = () => {
    if (!this.props.hotel.idGooglePlaces) {
      return;
    }
    const key = "AIzaSyCSYa00imNCRHgnXSy9sFO6SH1oFxZDx6o";
    var url =
      "https://us-central1-simple-trips.cloudfunctions.net/googlePlaceDetails";
    url = url + "?key=" + key;
    url = url + "&placeid=" + this.props.hotel.idGooglePlaces;
    axios.get(url).then(result => {
      this.setState({ reviews: result.data.result.reviews });
    });
  };

  componentWillMount() {
    this.fetchGoogleDetails();
  }

  render() {
    return (
      <div className="googlereviews">
        <img
          src="/assets/images/powered_by_google_on_white_hdpi.png"
          width="144"
          alt="Google Logo"
          className="google-attribution"
        />
        <div className="clear" />
        {this.state.reviews.map((review, index) => {
          return <HotelGoogleReviewsItem key={index} review={review} />;
        })}
        <div className="clear" />
      </div>
    );
  }
}

export default HotelGoogleReviews;
