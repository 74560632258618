import React from "react";
import axios from "axios";
import TripExpertHotelPickerItem from "./TripExpertHotelPickerItem";

class TripExpertHotelPicker extends React.Component {
  state = {
    hotels: [],
    priceCategory: 0
  };

  fetchHotelList = destinationId => {
    var url =
      "https://us-central1-simple-trips.cloudfunctions.net/tripExpertVenues?api_key=50295020f3bb8aeaf70eabe370603e4c" +
      "&destination_id=" +
      destinationId +
      "&venue_type_id=1&limit=500";
    if (this.state.priceCategory !== 0) {
      url = url + "&price_category_ids=" + this.state.priceCategory;
    }
    axios.get(url).then(result => {
      this.setState({
        hotels: result.data.response.venues,
        loading: false
      });
    });
  };

  handleChange = event => {
    event.preventDefault();
    this.setState({ priceCategory: event.currentTarget.value, hotels: [] });
    this.fetchHotelList(this.props.destination.tripExpertId);
  };

  componentDidMount() {
    this.fetchHotelList(this.props.destination.tripExpertId);
  }

  render() {
    return (
      <div className="tripexperthotelpickerinner">
        <h4>Trip Expert Suggestions</h4>

        <div className="priceselector">
          <label htmlFor="price">Price Category</label>
          <select
            type="text"
            name="price"
            onChange={this.handleChange}
            value={this.state.priceCategory}
          >
            <option value={0}>All</option>
            <option value={1}>Value</option>
            <option value={2}>Mid</option>
            <option value={3}>Lux</option>
          </select>
        </div>
        <div className="suggestions">
          {Object.keys(this.state.hotels).map(key => (
            <TripExpertHotelPickerItem
              hotel={this.state.hotels[key]}
              key={key}
              pickerCallback={this.props.pickerCallback}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default TripExpertHotelPicker;
