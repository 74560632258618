import React from "react";

import Footer from "../Static/Footer";
import Header from "../Static/Header";

class Pricing extends React.Component {
  componentDidMount() {
    // window.ga("send", "event", "foo");
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="pageframe pricing">
        <Header />

        <div className="wrapper">
          <h3>Pricing</h3>
          <p>Coming soon.</p>
        </div>

        <Footer />
      </div>
    );
  }
}

export default Pricing;
