import React from "react";
import Rater from "react-rater";
import "react-rater/lib/react-rater.css";

class HotelGoogleReviewsItem extends React.Component {
  render() {
    return (
      <div className="singlereview">
        <Rater
          total={5}
          rating={this.props.review.rating}
          interactive={false}
        />
        <p className="detail text">{this.props.review.text}</p>
        <div className="attribution">
          <img
            src={this.props.review.profile_photo_url}
            alt={this.props.review.author_name}
            width={40}
            className="profile"
          />
          <div className="about">
            <p className="detail authorname">{this.props.review.author_name}</p>
            <p className="detail timeago">
              {this.props.review.relative_time_description}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default HotelGoogleReviewsItem;
