import React from "react";
import Navbar from "./Navbar";

class QuickInfo extends React.Component {
  render() {
    return (
      <div className="management">
        <Navbar />
        <div className="mainpanel">
          <h3>Information</h3>

          <div className="infopanel">
            <h4 className="styled">Agency</h4>
            <p className="infoitem">CLIA #00341135</p>
          </div>

          <div className="infopanel">
            <h4 className="styled">Contact Information</h4>
            <p className="infoitem">Toll free number: (844) 707-8747</p>
            <p className="infoitem">Local number: (312) 487-3010</p>
          </div>
        </div>
      </div>
    );
  }
}

export default QuickInfo;
