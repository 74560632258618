import React from "react";
import Footer from "../Static/Footer";
import Header from "../Static/Header";
import PageTitle from "./Elements/PageTitle";
import { Helmet } from "react-helmet";

class FreeConsultation extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  frameContent = () => {
    const iframe = {
      __html:
        '<iframe class="scheduler_frame" src="https://calendly.com/simple-trips/free-consultation" width="100%" height="1000"></iframe>'
    };
    return iframe;
  };

  render() {
    return (
      <div className="pageframe consultation">
        <Header />
        <Helmet>
          {/* Generic  */}
          <title>
            Get a Free Consultation - Simple Trips - Business or Personal Travel
          </title>
          <meta
            name="description"
            content="Get a demo to learn how to save time and money using Simple Trips"
          />

          {/* Twitter */}
          <meta
            name="twitter:card"
            value="Get a demo to learn how to save time and money using Simple Trips"
          />

          {/* Open Graph */}
          <meta
            property="og:title"
            content="Get a Free Consultation - Simple Trips - Business or Personal Travel"
          />
          <meta property="og:type" content="article" />
          <meta
            property="og:url"
            content="https://simpletrips.com/free-consultation"
          />
          <meta
            property="og:image"
            content="https://simpletrips.com/assets/images/meta.png"
          />
          <meta
            property="og:description"
            content="Get a demo to learn how to save time and money using Simple Trips"
          />
        </Helmet>

        <div className="wrapper">
          <PageTitle
            title="Get a Free Consultation"
            subtitle="Simple Trips"
          />

          <div className="scheduler">
            <div dangerouslySetInnerHTML={this.frameContent()} />
          </div>

          <div className="clear" />
        </div>

        <Footer />
      </div>
    );
  }
}

export default FreeConsultation;
