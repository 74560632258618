import React from "react";

class PageTitle extends React.Component {
  render() {
    return (
      <div className="wrapper">
        <div className="pagetitlecontainer">
          <h1>{this.props.title}</h1>
          <h2 className="sublead">{this.props.subtitle}</h2>
        </div>

        <div className="clear" />
      </div>
    );
  }
}

export default PageTitle;
