import React from "react";
import base, { firebaseApp } from "../../base";
import RequestListItem from "./RequestListItem";
import LoadingSimple from "../Static/LoadingSimple";
import RequestEmpty from "./RequestEmpty";
import { Link } from "react-router-dom";

class RequestList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      requests: []
    };
  }

  componentWillMount() {
    this.setState({ loading: true });
    const uid = firebaseApp.auth().currentUser.uid;
    base.bindCollection("users/" + uid + "/requests", {
      context: this,
      state: "requests",
      withRefs: true,
      withIds: true,
      query: ref => {
        return ref
          .where("lastActivity", ">", 0)
          .orderBy("lastActivity", "desc");
      },
      then: data => {
        this.setState({ loading: false });
      }
    });
  }

  render() {
    var requests = this.state.requests.map((request, index) => {
      return <RequestListItem request={request} key={index} />;
    });
    return this.state.loading ? (
      <div className="spacing">
        <LoadingSimple />
      </div>
    ) : (
      <div>
        {this.state.requests.length === 0 ? (
          <RequestEmpty />
        ) : (
          <div>
            <h2>My Requests</h2>
            {requests}
            <div className="newrequestcontainer">
              <Link className="button newrequest" to="/requests/new">
                New Request
              </Link>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default RequestList;
