import React from "react";

import Footer from "../Static/Footer";
import Header from "../Static/Header";

class Recommended extends React.Component {
  componentDidMount() {
    document.body.classList.toggle("dashboard", false);
    // window.ga("send", "event", "foo");
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="legalpage">
        <Header />

        <div className="wrapper">
          <br />
          <br />
          <br />
          <h1>Recommended Travel Products</h1>
          <p>
            Simple Trips was founded on one straightforward mission: travel
            should be simple for everyone. We've got years of experience helping
            our clients book travel, and here are some of the best travel
            products we've come across for ourseleves or for our clients.
          </p>
          <br />
          <br />

          <div className="productrecommendations">
            <h4 className="styled">Carry-on Suitcase</h4>
            <a
              href="https://www.amazon.com/gp/product/B016IO9JFS/ref=as_li_ss_il?ie=UTF8&tag=&linkCode=li3&tag=simpletrips-20&linkId=75c71ec123aecf7a388b896e34420abd"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                border="0"
                alt="suitcase"
                src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B016IO9JFS&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=simpletrips-20"
              />
            </a>
            <img
              src="https://ir-na.amazon-adsystem.com/e/ir?t=simpletrips-20&l=li3&o=1&a=B016IO9JFS"
              width="1"
              height="1"
              border="0"
              alt="trk"
            />
            <p>
              With four spinner wheels and an incredbily light weight, the{" "}
              <a
                href="https://amzn.to/2zi13Gt"
                target="_blank"
                rel="noopener noreferrer"
              >
                TravelPro Maxlite 4 Carry-On
              </a>{" "}
              is one of the best.
            </p>
            <br />
            <br />

            {/* Next */}
            <h4 className="styled">Full-size Suitcase</h4>
            <a
              href="https://www.amazon.com/gp/product/B016IO9JN0/ref=as_li_ss_il?ie=UTF8&linkCode=li3&tag=simpletrips-20&linkId=4cfe5c81887fceb706ecebc613c3669b"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                border="0"
                alt="suitcase"
                src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B016IO9JN0&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=simpletrips-20"
              />
            </a>
            <img
              src="https://ir-na.amazon-adsystem.com/e/ir?t=simpletrips-20&l=li3&o=1&a=B016IO9JN0"
              width="1"
              height="1"
              border="0"
              alt="trk"
            />
            <p>
              Similar to the carry-on, the{" "}
              <a
                href="https://amzn.to/2KCv20Z"
                target="_blank"
                rel="noopener noreferrer"
              >
                TravelPro Maxlite 4 Full-size (25-inch)
              </a>{" "}
              is fantastic. It has four spinner wheels, as well as an extremely
              lightweight design. The suitcase retails for approximately $115.
            </p>
            <br />
            <br />

            {/* Next */}
            <h4 className="styled">Liquid Travel Containers</h4>
            <a
              href="https://www.amazon.com/Kitdine-Portable-Silicone-Travel-bottles/dp/B00WO69JOQ/ref=as_li_ss_il?s=beauty&ie=UTF8&qid=1530973190&sr=1-11&keywords=travel+containers&linkCode=li3&tag=simpletrips-20&linkId=c4c088f3660600177940b5447f32d95b"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                border="0"
                alt="bottles"
                src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B00WO69JOQ&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=simpletrips-20"
              />
            </a>
            <img
              src="https://ir-na.amazon-adsystem.com/e/ir?t=simpletrips-20&l=li3&o=1&a=B00WO69JOQ"
              width="1"
              height="1"
              border="0"
              alt="trk"
            />
            <p>
              These{" "}
              <a
                href="https://amzn.to/2uetFL5"
                target="_blank"
                rel="noopener noreferrer"
              >
                Soft Silicone Travel Bottles
              </a>{" "}
              are the perfect size at 3oz. They're TSA approved, and they can
              hold shampoo, body wash, conditioner, or other necessary liquids.
            </p>

            {/* end reco's container */}
          </div>

          {/* end outer container */}
        </div>

        <Footer />
      </div>
    );
  }
}

export default Recommended;
