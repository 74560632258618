import React from "react";
import base from "../../base";
import DestinationListItem from "./DestinationListItem";

class DestinationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      destinations: []
    };
  }

  componentWillMount() {
    base.bindCollection("destinations", {
      context: this,
      state: "destinations",
      withRefs: true,
      withIds: true,
      query: ref => {
        return ref
          .where("lastActivity", ">", 0)
          .orderBy("lastActivity", "desc");
      }
    });
  }

  render() {
    var destinations = this.state.destinations.map((destination, index) => {
      return (
        <DestinationListItem
          destination={destination}
          key={index}
          viewLinks={true}
        />
      );
    });
    return <div>{destinations}</div>;
  }
}

export default DestinationList;
