import React from "react";
import { Link } from "react-router-dom";

class RequestEmpty extends React.Component {
  render() {
    return (
      <div>
        <div className="requestempty">
          <h2>Welcome</h2>
          <div className="intro">
            <p>
              We're here to help you plan and book travel. Here are some things
              to try. Take a look and tap Get Started when you're ready.
            </p>
          </div>

          <div className="featureblock">
            <div className="featuretitle">Find a flight</div>
            <div className="feature">
              I need a <span>flight</span> from <span>Chicago</span> to{" "}
              <span>Denver</span> on <span>April 19th</span>.
            </div>
            <div className="feature">
              When is the <span>cheapest</span> time to fly to{" "}
              <span>Hong Kong</span> nonstop?
            </div>
            <div className="feature">
              Can I use my <span>miles</span> to fly to <span>London</span> in
              the fall?
            </div>
          </div>

          <div className="featureblock">
            <div className="featuretitle">Research hotels</div>
            <div className="feature">
              Where is the <span>most affordable</span> hotel with{" "}
              <span>3 stars</span> in <span>Austin</span>?
            </div>
            <div className="feature">
              Should I use <span>points</span> or <span>pay cash</span> for my
              trip to <span>San Francisco</span>?
            </div>
            <div className="feature">
              Is a <span>hotel</span> or <span>vacation rental</span> better for
              my trip?
            </div>
          </div>

          <div className="featureblock">
            <div className="featuretitle">Find the best deal</div>
            <div className="feature">
              Can you send me some <span>flight deals</span> from{" "}
              <span>New York</span>?
            </div>
            <div className="feature">
              Should I book <span>now</span> or <span>wait</span> for a better
              price?
            </div>
            <div className="feature">
              I have a <span>flight</span> in mind, is it the{" "}
              <span>best price</span> out there?
            </div>
          </div>
          <div className="clear" />

          <div className="getstartedblock">
            <Link className="button" to="/requests/new">
              Get Started
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default RequestEmpty;
