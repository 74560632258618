import React from "react";

class Callout extends React.Component {
  render() {
    return (
      <div className="wrapper">
        <div className="callout">
          {this.props.title ? (
            <h4 className="styled">{this.props.title}</h4>
          ) : (
            ""
          )}
          {this.props.description ? <p>{this.props.description}</p> : ""}
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Callout;
