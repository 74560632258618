import React from "react";
import base from "../../base";
import DestinationListItem from "./DestinationListItem";
import { Link } from "react-router-dom";

class DestinationPickList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      destinations: []
    };
  }

  componentWillMount() {
    base.bindCollection("destinations", {
      context: this,
      state: "destinations",
      withRefs: true,
      withIds: true,
      query: ref => {
        return ref
          .where("lastActivity", ">", 0)
          .orderBy("lastActivity", "desc");
      }
    });
  }

  render() {
    var destinations = this.state.destinations.map((destination, index) => {
      return (
        <DestinationListItem
          destination={destination}
          key={index}
          pickerCallback={this.props.pickerCallback}
        />
      );
    });
    return (
      <div>
        <Link to="/manage/destinations/new">Add New</Link> <br /> <br />
        {destinations}
      </div>
    );
  }
}

export default DestinationPickList;
