import React from "react";

class HotelAmenities extends React.Component {
  render() {
    return (
      <div className="amenities">
        <div className="display">
          {this.props.amenities.map((amenity, index) => {
            return (
              <div key={index} className="amenity">
                {amenity.amenity}
              </div>
            );
          })}
          <div className="clear" />
        </div>
      </div>
    );
  }
}

export default HotelAmenities;
