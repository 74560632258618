import React from "react";
import axios from "axios";
import GooglePlaceIdPickerItem from "./GooglePlaceIdPickerItem";

class GooglePlaceIdPicker extends React.Component {
  state = {
    suggestions: []
  };

  fetchGooglePlaces = (query, lat, lon) => {
    const key = "AIzaSyCSYa00imNCRHgnXSy9sFO6SH1oFxZDx6o";
    var url =
      "https://us-central1-simple-trips.cloudfunctions.net/googlePlaceSuggest";
    url = url + "?key=" + key;
    url = url + "&input=" + encodeURIComponent(query);
    url = url + "&location=" + lat + "," + lon;
    url = url + "&radius=10000";
    axios.get(url).then(result => {
      this.setState({ suggestions: result.data.predictions });
      console.log(result.data.predictions);
    });
  };

  handleInput = event => {
    event.preventDefault();
    const query = this.queryRef.value;
    this.setState({ suggestions: [] });
    this.fetchGooglePlaces(query, this.props.latitude, this.props.longitude);
  };

  componentDidMount() {
    this.fetchGooglePlaces(
      this.props.query,
      this.props.latitude,
      this.props.longitude
    );
  }

  render() {
    return (
      <div className="googleplacepicker">
        <div>
          <label htmlFor="slug" className="descriptor">
            Google Place Query{" "}
          </label>{" "}
          <br />
          <br />
          <br />
          <form action="" className="googleplace" onSubmit={this.handleInput}>
            <input
              autoComplete="off"
              ref={queryRef => (this.queryRef = queryRef)}
              name="slug"
              placeholder="Query"
              type="text"
              className="detail"
              defaultValue={this.props.query}
            />
            <button type="submit" className="add">
              Update
            </button>
          </form>
          {Object.keys(this.state.suggestions).map(key => (
            <GooglePlaceIdPickerItem
              suggestion={this.state.suggestions[key]}
              key={key}
              pickerCallback={this.props.pickerCallback}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default GooglePlaceIdPicker;
