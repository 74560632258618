import React from "react";
import { Helmet } from "react-helmet";

import Footer from "../Static/Footer";
import Header from "../Static/Header";

class BusinessDemo extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  frameContent = () => {
    const iframe = {
      __html:
        '<iframe class="scheduler_frame" src="https://calendly.com/simple-trips/corporate-travel-demo" width="100%" height="1000"></iframe>'
    };
    return iframe;
  };

  render() {
    return (
      <div className="pageframe business demo">
        <Header />
        <Helmet>
          {/* Generic  */}
          <title>
            Get a Demo - Simple Trips for Business - Easy Managed Travel.
            Guaranteed.
          </title>
          <meta
            name="description"
            content="Get a demo to learn how to save time and money using our Managed Travel solution, which is customized to your individual business needs."
          />

          {/* Twitter */}
          <meta
            name="twitter:card"
            value="Get a demo to learn how to save time and money using our Managed Travel solution, which is customized to your individual business needs."
          />

          {/* Open Graph */}
          <meta
            property="og:title"
            content="Get a Demo - Simple Trips for Business - Easy Managed Travel. Guaranteed."
          />
          <meta property="og:type" content="article" />
          <meta
            property="og:url"
            content="https://simpletrips.com/business-travel/demo"
          />
          <meta
            property="og:image"
            content="https://simpletrips.com/assets/images/meta.png"
          />
          <meta
            property="og:description"
            content="Get a demo to learn how to save time and money using our Managed Travel solution, which is customized to your individual business needs."
          />
        </Helmet>

        <div className="wrapper">
          <div className="pagetitlecontainer">
            <h1>Book a Demo</h1>
          </div>

          <div className="scheduler">
            <div dangerouslySetInnerHTML={this.frameContent()} />
          </div>

          <div className="clear" />
        </div>

        <Footer />
      </div>
    );
  }
}

export default BusinessDemo;
