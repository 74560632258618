import React, { Component } from "react";
import { Link } from "react-router-dom";

class Header extends Component {
  render() {
    return (
      <div>
        <div className="headerbar">
          <div className="branding">
            <Link to="/">
              <img
                src="/assets/images/logo.png"
                width="24"
                alt="Simple Trips Logo"
              />
            </Link>
          </div>

          {/* Right navigation */}
          {this.props.hideLinks === true ? (
            ""
          ) : (
            <ul className="navlinks">
              <li className="item">
                <Link to="/business-travel" className="link business">
                  Business Travel
                </Link>
              </li>
              <li className="item">
                <Link to="/personal-travel" className="link personal">
                  Personal Travel
                </Link>
              </li>
              {/* 
            <li className="item">
              <Link to="/pricing" className="link pricing">
                Pricing
              </Link>
            </li> 
            
              <li className="item">
                <Link to="/login" className="link login">
                  Log In
                </Link>
              </li> */}
            </ul>
          )}

          {/* Clear - fixes box parent */}
          <div className="clear" />
        </div>
      </div>
    );
  }
}

export default Header;
