import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";

class NotFound extends Component {
  render() {
    return (
      <div>
        <Header />
        <div className="wrapper">
          <div className="hero">
            <h1>Oops. We couldn't find that.</h1>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default NotFound;
