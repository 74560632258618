import React from "react";

class HotelMap extends React.Component {
  render() {
    const center = [
      Number(this.props.hotel.latitude),
      Number(this.props.hotel.longitude)
    ];
    return (
      <div>todo</div>
    );
  }
}
export default HotelMap;
