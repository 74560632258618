import React from "react";

import Footer from "../Static/Footer";
import Header from "../Static/Header";

class LegalTerms extends React.Component {
  componentDidMount() {
    document.body.classList.toggle("dashboard", false);
    // window.ga("send", "event", "foo");
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="legalpage">
        <Header />

        <div className="wrapper">
          <h3>Terms and Conditions</h3>

          <h4>Agreement between User and simpletrips.com </h4>
          <p>
            Welcome to simpletrips.com. The simpletrips.com website (the "Site")
            is comprised of various web pages operated by Simple Trips, Inc.
            ("Simple Trips"). simpletrips.com is offered to you conditioned on
            your acceptance without modification of the terms, conditions, and
            notices contained herein (the "Terms"). Your use of simpletrips.com
            constitutes your agreement to all such Terms. Please read these
            terms carefully, and keep a copy of them for your reference.{" "}
          </p>

          <h4>Privacy </h4>
          <p>
            Your use of simpletrips.com is subject to our Privacy Policy. Please
            review our Privacy Policy, which also governs the Site and informs
            users of our data collection practices.{" "}
          </p>

          <h4>Electronic Communications </h4>
          <p>
            Visiting simpletrips.com or sending emails to Simple Trips
            constitutes electronic communications. You consent to receive
            electronic communications and you agree that all agreements,
            notices, disclosures and other communications that we provide to you
            electronically, via email and on the Site, satisfy any legal
            requirement that such communications be in writing.{" "}
          </p>

          <h4>Your Account </h4>
          <p>
            If you use this site, you are responsible for maintaining the
            confidentiality of your account and password and for restricting
            access to your computer, and you agree to accept responsibility for
            all activities that occur under your account or password. You may
            not assign or otherwise transfer your account to any other person or
            entity. You acknowledge that Simple Trips is not responsible for
            third party access to your account that results from theft or
            misappropriation of your account. Simple Trips and its associates
            reserve the right to refuse or cancel service, terminate accounts,
            or remove or edit content in our sole discretion.{" "}
          </p>

          <h4>Links to Third Party Sites/Third Party Services </h4>
          <p>
            simpletrips.com may contain links to other websites ("Linked
            Sites"). The Linked Sites are not under the control of Simple Trips
            and Simple Trips is not responsible for the contents of any Linked
            Site, including without limitation any link contained in a Linked
            Site, or any changes or updates to a Linked Site. Simple Trips is
            providing these links to you only as a convenience, and the
            inclusion of any link does not imply endorsement by Simple Trips of
            the site or any association with its operators.{" "}
          </p>

          <p>
            Certain services made available via simpletrips.com are delivered by
            third party sites and organizations. By using any product, service
            or functionality originating from the simpletrips.com domain, you
            hereby acknowledge and consent that Simple Trips may share such
            information and data with any third party with whom Simple Trips has
            a contractual relationship to provide the requested product, service
            or functionality on behalf of simpletrips.com users and customers.{" "}
          </p>

          <h4>No Unlawful or Prohibited Use/Intellectual Property </h4>
          <p>
            You are granted a non-exclusive, non-transferable, revocable license
            to access and use simpletrips.com strictly in accordance with these
            terms of use. As a condition of your use of the Site, you warrant to
            Simple Trips that you will not use the Site for any purpose that is
            unlawful or prohibited by these Terms. You may not use the Site in
            any manner which could damage, disable, overburden, or impair the
            Site or interfere with any other party's use and enjoyment of the
            Site. You may not obtain or attempt to obtain any materials or
            information through any means not intentionally made available or
            provided for through the Site.{" "}
          </p>

          <p>
            You will not modify, publish, transmit, reverse engineer,
            participate in the transfer or sale, create derivative works, or in
            any way exploit any of the content, in whole or in part, found on
            the Site. Simple Trips content is not for resale. Your use of the
            Site does not entitle you to make any unauthorized use of any
            protected content, and in particular you will not delete or alter
            any proprietary rights or attribution notices in any content. You
            will use protected content solely for your personal use, and will
            make no other use of the content without the express written
            permission of Simple Trips and the copyright owner. You agree that
            you do not acquire any ownership rights in any protected content. We
            do not grant you any licenses, express or implied, to the
            intellectual property of Simple Trips or our licensors except as
            expressly authorized by these Terms.{" "}
          </p>

          <h4>Third Party Accounts </h4>
          <p>
            You will be able to connect your Simple Trips account to third party
            accounts. By connecting your Simple Trips account to your third
            party account, you acknowledge and agree that you are consenting to
            the continuous release of information about you to others (in
            accordance with your privacy settings on those third party sites).
            If you do not want information about you to be shared in this
            manner, do not use this feature.{" "}
          </p>

          <h4>International Users </h4>
          <p>
            The Service is controlled, operated and administered by Simple Trips
            from our offices within the USA. If you access the Service from a
            location outside the USA, you are responsible for compliance with
            all local laws. You agree that you will not use the Simple Trips
            Content accessed through simpletrips.com in any country or in any
            manner prohibited by any applicable laws, restrictions or
            regulations.{" "}
          </p>

          <h4>Indemnification </h4>
          <p>
            You agree to indemnify, defend and hold harmless Simple Trips, its
            officers, directors, employees, agents and third parties, for any
            losses, costs, liabilities and expenses (including reasonable
            attorney's fees) relating to or arising out of your use of or
            inability to use the Site or services, any user postings made by
            you, your violation of any terms of this Agreement or your violation
            of any rights of a third party, or your violation of any applicable
            laws, rules or regulations. Simple Trips reserves the right, at its
            own cost, to assume the exclusive defense and control of any matter
            otherwise subject to indemnification by you, in which event you will
            fully cooperate with Simple Trips in asserting any available
            defenses.{" "}
          </p>

          <h4>Liability Disclaimer </h4>
          <p>
            THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR
            AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL
            ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN.
            SIMPLE TRIPS, INC. AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR
            CHANGES IN THE SITE AT ANY TIME.{" "}
          </p>

          <p>
            SIMPLE TRIPS, INC. AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS
            ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND
            ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND
            RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE
            MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION,
            SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS
            IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. SIMPLE TRIPS, INC.
            AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS
            WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND
            RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
            NON-INFRINGEMENT.{" "}
          </p>

          <p>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
            SIMPLE TRIPS, INC. AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT,
            INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR
            ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR
            LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED
            WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY
            TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO
            PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS,
            SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR
            OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON
            CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF
            SIMPLE TRIPS, INC. OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE
            POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT
            ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR
            INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF
            YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF
            THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE
            USING THE SITE.{" "}
          </p>

          <h4>Termination/Access Restriction </h4>
          <p>
            Simple Trips reserves the right, in its sole discretion, to
            terminate your access to the Site and the related services or any
            portion thereof at any time, without notice. To the maximum extent
            permitted by law, this agreement is governed by the laws of the
            State of Illinois and you hereby consent to the exclusive
            jurisdiction and venue of courts in Illinois in all disputes arising
            out of or relating to the use of the Site. Use of the Site is
            unauthorized in any jurisdiction that does not give effect to all
            provisions of these Terms, including, without limitation, this
            section.{" "}
          </p>

          <p>
            You agree that no joint venture, partnership, employment, or agency
            relationship exists between you and Simple Trips as a result of this
            agreement or use of the Site. Simple Trips's performance of this
            agreement is subject to existing laws and legal process, and nothing
            contained in this agreement is in derogation of Simple Trips's right
            to comply with governmental, court and law enforcement requests or
            requirements relating to your use of the Site or information
            provided to or gathered by Simple Trips with respect to such use. If
            any part of this agreement is determined to be invalid or
            unenforceable pursuant to applicable law including, but not limited
            to, the warranty disclaimers and liability limitations set forth
            above, then the invalid or unenforceable provision will be deemed
            superseded by a valid, enforceable provision that most closely
            matches the intent of the original provision and the remainder of
            the agreement shall continue in effect.{" "}
          </p>

          <p>
            Unless otherwise specified herein, this agreement constitutes the
            entire agreement between the user and Simple Trips with respect to
            the Site and it supersedes all prior or contemporaneous
            communications and proposals, whether electronic, oral or written,
            between the user and Simple Trips with respect to the Site. A
            printed version of this agreement and of any notice given in
            electronic form shall be admissible in judicial or administrative
            proceedings based upon or relating to this agreement to the same
            extent and subject to the same conditions as other business
            documents and records originally generated and maintained in printed
            form. It is the express wish to the parties that this agreement and
            all related documents be written in English.{" "}
          </p>

          <h4>Changes to Terms </h4>
          <p>
            Simple Trips reserves the right, in its sole discretion, to change
            the Terms under which simpletrips.com is offered. The most current
            version of the Terms will supersede all previous versions. Simple
            Trips encourages you to periodically review the Terms to stay
            informed of our updates.{" "}
          </p>

          <h4>Contact Us </h4>
          <p>
            Simple Trips welcomes your questions or comments regarding the
            Terms:{" "}
          </p>

          <p>
            Simple Trips, Inc. <br />
            917 W WASHINGTON BLVD STE 215
            <br />
            CHICAGO, ILLINOIS 60607{" "}
          </p>

          <p>Email Address: legal@simpletrips.com   </p>
          <p>Telephone number: 844-707-8747 </p>

          <p>Effective as of June 01, 2018 </p>
        </div>

        <Footer />
      </div>
    );
  }
}

export default LegalTerms;
