import React from "react";
import Navbar from "./Navbar";
import DestinationPickList from "./DestinationPickList";
import GooglePlaceIdPicker from "./GooglePlaceIdPicker";
import TripExpertHotelPicker from "./TripExpertHotelPicker";
import Slug from "slug";
import base from "../../base";

class AddHotel extends React.Component {
  state = {
    destination: null,
    showTripExpertFinder: false,
    showGoogleIdFinder: false,
    googleplaceidfinder: false,
    tripExpertVenue: null
  };

  createHotel = event => {
    event.preventDefault();

    const hotelName = this.hotelNameRef.value;
    const latitude = this.latitudeRef.value;
    const longitude = this.longitudeRef.value;
    const address = this.addressRef.value;
    const phone = this.phoneRef.value;
    const website = this.websiteRef.value;
    const starRating = this.starRatingRef.value;
    const tripExpertId = this.tripExpertIdRef.value;
    const eanId = this.eanIdRef.value;
    const travelPayoutsId = this.travelPayoutsIdRef.value;
    const expediaId = this.expediaIdRef.value;
    const bookingDotComId = this.bookingDotComIdRef.value;
    const googlePlacesId = this.googlePlacesIdRef.value;
    const slug = this.slugRef.value;
    const timestamp = new Date().getTime();

    if (
      !hotelName.length ||
      !latitude.length ||
      !longitude.length ||
      !address.length ||
      !phone.length ||
      !website.length ||
      !starRating.length ||
      !slug.length ||
      !this.state.destination
    ) {
      return;
    }

    const hotelData = {
      destinationId: this.state.destination.id,
      name: hotelName,
      latitude: latitude,
      longitude: longitude,
      address: address,
      phone: phone,
      website: website,
      starRating: starRating,
      idTripExpert: tripExpertId ? tripExpertId : "",
      idEan: eanId ? eanId : "",
      idTravelPayouts: travelPayoutsId ? travelPayoutsId : "",
      idExpedia: expediaId ? expediaId : "",
      idBookingDotCom: bookingDotComId ? bookingDotComId : "",
      idGooglePlaces: googlePlacesId ? googlePlacesId : "",
      slug: slug,
      lastActivity: timestamp,
      createdAt: timestamp
    };

    console.log(hotelData);
    // Create the hotel in our hotel root collection
    base
      .addToCollection("hotels", hotelData)
      .then(hotelDocument => {
        console.log(hotelDocument);
        // Redirect to the index
        this.props.history.push("/manage");
      })
      .catch(error => {
        console.log(error);
      });
  };

  pickDestination = destination => {
    this.setState({ destination: destination });
  };

  pickedGooglePlace = place => {
    this.setState({ googlePlace: place });
    this.googlePlacesIdRef.value = place.place_id;

    window.scrollTo(0, 0);
  };

  pickedTripExpertHotel = (venue, mapping, tpId) => {
    this.setState({ tripExpertVenue: venue });
    this.hotelNameRef.value = venue.name;
    this.latitudeRef.value = venue.latitude;
    this.longitudeRef.value = venue.longitude;
    this.addressRef.value = venue.address;
    this.phoneRef.value = venue.telephone;
    this.websiteRef.value = venue.website;
    this.starRatingRef.value = venue.star_rating;
    this.tripExpertIdRef.value = venue.id;
    this.eanIdRef.value = mapping.eanId;
    this.travelPayoutsIdRef.value = tpId;
    this.expediaIdRef.value = mapping.expediaId;
    this.bookingDotComIdRef.value = mapping.bookingDotComId;
    this.slugRef.value = Slug(venue.name).toLowerCase();

    window.scrollTo(0, 0);
  };

  render() {
    return (
      <div className="management">
        <Navbar />
        <div className="mainpanel">
          <h3>Add Hotel</h3>

          {this.state.destination ? (
            <div>
              <p className="destinationname">
                Destination: {this.state.destination.name}
                <button
                  onClick={() => {
                    this.setState({ destination: null });
                  }}
                >
                  change
                </button>
              </p>
            </div>
          ) : (
            <div>
              <br />
              <h4>Choose Destination</h4>
              <DestinationPickList pickerCallback={this.pickDestination} />
              <br />
              <br />
            </div>
          )}

          <h4>Hotel Data</h4>
          <div className="addform hotel">
            <form action="" className="addhotel" onSubmit={this.createHotel}>
              <label htmlFor="hotelName" className="descriptor">
                Name{" "}
              </label>{" "}
              <br />
              <input
                autoComplete="off"
                ref={hotelNameRef => (this.hotelNameRef = hotelNameRef)}
                name="destinationName"
                placeholder="Name"
                type="text"
                className="detail"
              />
              <br />
              <label htmlFor="latitude" className="descriptor">
                Latitude{" "}
              </label>{" "}
              <br />
              <input
                autoComplete="off"
                ref={latitudeRef => (this.latitudeRef = latitudeRef)}
                name="latitude"
                placeholder="Latitude"
                type="text"
                className="detail"
              />
              <br />
              <label htmlFor="longitude" className="descriptor">
                Longitude{" "}
              </label>{" "}
              <br />
              <input
                autoComplete="off"
                ref={longitudeRef => (this.longitudeRef = longitudeRef)}
                name="longitude"
                placeholder="Longitude"
                type="text"
                className="detail"
              />
              <br />
              <label htmlFor="address" className="descriptor">
                Adddress{" "}
              </label>{" "}
              <br />
              <input
                autoComplete="off"
                ref={addressRef => (this.addressRef = addressRef)}
                name="address"
                placeholder="Address"
                type="text"
                className="detail"
              />
              <br />
              <label htmlFor="phone" className="descriptor">
                Phone{" "}
              </label>{" "}
              <br />
              <input
                autoComplete="off"
                ref={phoneRef => (this.phoneRef = phoneRef)}
                name="phone"
                placeholder="Phone Number"
                type="text"
                className="detail"
              />
              <br />
              <label htmlFor="website" className="descriptor">
                Website{" "}
              </label>{" "}
              <br />
              <input
                autoComplete="off"
                ref={websiteRef => (this.websiteRef = websiteRef)}
                name="website"
                placeholder="Website"
                type="text"
                className="detail"
              />
              <br />
              <label htmlFor="starRating" className="descriptor">
                Star Rating{" "}
              </label>{" "}
              <br />
              <input
                autoComplete="off"
                ref={starRatingRef => (this.starRatingRef = starRatingRef)}
                name="starRating"
                placeholder="Star Rating"
                type="text"
                className="detail"
              />
              <br />
              <label htmlFor="googlePlacesId" className="descriptor">
                Google Places ID{" "}
              </label>{" "}
              <br />
              <input
                autoComplete="off"
                ref={googlePlacesIdRef =>
                  (this.googlePlacesIdRef = googlePlacesIdRef)
                }
                name="googlePlacesId"
                placeholder="Google Places ID"
                type="text"
                className="detail"
              />
              <br />
              <label htmlFor="tripExpertIdRef" className="descriptor">
                Trip Expert ID{" "}
              </label>{" "}
              <br />
              <input
                autoComplete="off"
                ref={tripExpertIdRef =>
                  (this.tripExpertIdRef = tripExpertIdRef)
                }
                name="tripExpertId"
                placeholder="Trip Expert ID"
                type="text"
                className="detail"
              />
              <br />
              <label htmlFor="eanId" className="descriptor">
                EAN ID{" "}
              </label>{" "}
              <br />
              <input
                autoComplete="off"
                ref={eanIdRef => (this.eanIdRef = eanIdRef)}
                name="eanId"
                placeholder="EAN ID"
                type="text"
                className="detail"
              />
              <br />
              <label htmlFor="travelPayoutsId" className="descriptor">
                TravelPayouts ID{" "}
              </label>{" "}
              <br />
              <input
                autoComplete="off"
                ref={travelPayoutsIdRef =>
                  (this.travelPayoutsIdRef = travelPayoutsIdRef)
                }
                name="travelPayoutsId"
                placeholder="Travel Payouts ID"
                type="text"
                className="detail"
              />
              <br />
              <label htmlFor="expediaId" className="descriptor">
                Expedia ID{" "}
              </label>{" "}
              <br />
              <input
                autoComplete="off"
                ref={expediaIdRef => (this.expediaIdRef = expediaIdRef)}
                name="expediaId"
                placeholder="Expedia ID"
                type="text"
                className="detail"
              />
              <br />
              <label htmlFor="bookingDotComId" className="descriptor">
                Booking.com ID{" "}
              </label>{" "}
              <br />
              <input
                autoComplete="off"
                ref={bookingDotComIdRef =>
                  (this.bookingDotComIdRef = bookingDotComIdRef)
                }
                name="bookingDotComId"
                placeholder="Booking.com ID"
                type="text"
                className="detail"
              />
              <br />
              <label htmlFor="slug" className="descriptor">
                URL Slug{" "}
              </label>{" "}
              <br />
              <input
                autoComplete="off"
                ref={slugRef => (this.slugRef = slugRef)}
                name="slug"
                placeholder="Slug"
                type="text"
                className="detail"
              />
              <br />
              <button type="submit" className="add">
                Add Hotel
              </button>
            </form>
          </div>

          <div className="tripexperthotelpicker">
            {this.state.showTripExpertFinder ? (
              this.state.destination ? (
                <div>
                  <button
                    onClick={() => {
                      this.setState({ showTripExpertFinder: false });
                    }}
                    className="general"
                  >
                    Hide Suggestions
                  </button>
                  <TripExpertHotelPicker
                    destination={this.state.destination}
                    pickerCallback={this.pickedTripExpertHotel}
                  />
                </div>
              ) : (
                "pick destination first"
              )
            ) : (
              <button
                onClick={() => {
                  this.setState({ showTripExpertFinder: true });
                }}
                className="general"
              >
                Show Trip Expert Suggestions
              </button>
            )}
          </div>

          <div className="googleplaceidfinder">
            {this.state.showGoogleIdFinder ? (
              this.state.destination ? (
                <div>
                  <button
                    onClick={() => {
                      this.setState({ showGoogleIdFinder: false });
                    }}
                    className="general"
                  >
                    Hide Picker
                  </button>
                  <GooglePlaceIdPicker
                    query={this.hotelNameRef.value}
                    latitude={this.state.destination.latitude}
                    longitude={this.state.destination.longitude}
                    pickerCallback={this.pickedGooglePlace}
                  />
                </div>
              ) : (
                "pick destination first"
              )
            ) : (
              <button
                onClick={() => {
                  this.setState({ showGoogleIdFinder: true });
                }}
                className="general"
              >
                Find Google Place ID
              </button>
            )}
          </div>
          <div className="clear" />
        </div>
      </div>
    );
  }
}

export default AddHotel;
