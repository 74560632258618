import React from "react";

class BlogRecentItem extends React.Component {
  render() {
    return (
      <div className="blogitem">
        <a href={this.props.item.link + "?utm_source=blog_container_on_site"}>
          <div className="container">
            <div className="imgwrapper">
              {this.props.item.enclosure != null &&
              this.props.item.enclosure.type === "image/*" ? (
                <img
                  src={this.props.item.enclosure.url}
                  alt="Item Title"
                  className="cover"
                />
              ) : (
                ""
              )}
            </div>

            <p className="title">{this.props.item.title}</p>
            <p className="snippet">{this.props.item.contentSnippet}</p>
            <div className="button small">Read more</div>
          </div>
        </a>
      </div>
    );
  }
}

export default BlogRecentItem;
