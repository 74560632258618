import React from "react";
import {
  signIn,
  authenticateWithFacebook,
  authenticateWithGoogle
} from "../../helpers/auth";
import { Link } from "react-router-dom";

import Footer from "../Static/Footer";
import Header from "../Static/Header";
import LoadingSimple from "../Static/LoadingSimple";

class Login extends React.Component {
  state = {
    errorMessage: null,
    loading: false
  };

  componentDidMount() {
    // window.ga("send", "event", "foo");
    window.scrollTo(0, 0);
  }

  performLogin = event => {
    event.preventDefault();
    this.setState({ loading: true });

    const email = this.emailRef.value;
    const password = this.passwordRef.value;
    signIn(email, password).catch(error => {
      var simpleMessage = "";
      switch (error.code) {
        case "auth/invalid-email": {
          simpleMessage = "Please enter a valid email address.";
          break;
        }
        case "auth/user-disabled": {
          simpleMessage = "Your account has been disabled.";
          break;
        }
        case "auth/user-not-found": {
          simpleMessage = "The email address entered does not have an account.";
          break;
        }
        case "auth/wrong-password": {
          simpleMessage = "Incorrect password.";
          break;
        }
        default: {
          simpleMessage = "There was a problem. Please try again.";
        }
      }
      this.setState({ errorMessage: simpleMessage, loading: false });
    });
  };

  performFacebookAuth = () => {
    authenticateWithFacebook();
  };

  performGoogleAuth = () => {
    authenticateWithGoogle();
  };

  render() {
    return (
      <div className="pageframe login">
        <Header />

        <div className="authbox">
          <div className="title">Log in</div>
          {this.state.loading ? (
            <div className="spacing">
              <LoadingSimple />
            </div>
          ) : (
            <div>
              {this.state.errorMessage ? (
                <div className="errorbox">
                  <i className="fas fa-exclamation-circle erroricon" />
                  <p className="errormessage">{this.state.errorMessage}</p>
                  <div className="clear" />
                </div>
              ) : (
                ""
              )}
              <form action="" className="login" onSubmit={this.performLogin}>
                <input
                  className="login"
                  ref={emailRef => (this.emailRef = emailRef)}
                  name="email"
                  placeholder="Email"
                  type="email"
                />
                <input
                  className="login"
                  ref={passwordRef => (this.passwordRef = passwordRef)}
                  name="password"
                  placeholder="Password"
                  type="password"
                />
                <button className="button login traditional" type="submit">
                  Continue
                </button>
              </form>
              <Link to="/account-recovery" className="button login minimal">
                Need password help?
              </Link>

              <div className="divider" />

              <button
                onClick={this.performFacebookAuth}
                className="button login facebook"
              >
                <i className="fab fa-facebook-f authicon" />Sign in with
                Facebook
              </button>
              <button
                onClick={this.performGoogleAuth}
                className="button login google"
              >
                <i className="fab fa-google authicon" />Sign in with Google
              </button>
            </div>
          )}
        </div>

        <div className="authswitchbox">
          <div className="title">
            <Link to="/sign-up">Don't have an account? </Link>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default Login;
