import React from "react";
import TopBar from "./TopBar";
import LoadingSimple from "../Static/LoadingSimple";
import base, { firebaseApp } from "../../base";

class RequestViewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      request: null,
      loading: true,
      requestId: this.props.match.params.requestid
    };
  }

  componentWillMount() {
    const uid = firebaseApp.auth().currentUser.uid;

    base.listenToDoc("users/" + uid + "/requests/" + this.state.requestId, {
      context: this,
      then(data) {
        this.setState({ request: data, loading: false });
      },
      onFailure(err) {
        //handle error
      }
    });
  }

  render() {
    return (
      <div className="app-page requestview">
        <TopBar />
        <div className="main-window">
          <div className="container">
            {this.state.loading ? (
              <div className="spacing">
                <LoadingSimple />
              </div>
            ) : (
              <div>
                <p>{this.state.request.description}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default RequestViewPage;
