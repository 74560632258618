import React from "react";

class TripExpertDestinationListItem extends React.Component {
  componentDidMount() {
    this.setState({ item: this.props.destination });
  }

  select = () => {
    this.props.selectFrom(this.props.destination);
  };

  view = () => {
    const url =
      "https://www.tripexpert.com/" + this.props.destination.permalink;
    var win = window.open(url, "_blank");
    win.focus();
  };

  render() {
    return (
      <div className="item">
        <p className="detail name">{this.props.destination.name} </p>
        <p className="detail">
          <button
            className="choose"
            onClick={() => {
              this.select();
            }}
          >
            <i className="fas fa-check" /> Select
          </button>
          <button
            className="choose"
            onClick={() => {
              this.view();
            }}
          >
            <i className="fas fa-arrow-right" /> View
          </button>
        </p>
        <p className="detail country">{this.props.destination.country_name} </p>
        <p className="detail latlon">
          {this.props.destination.latitude +
            ", " +
            this.props.destination.longitude}
        </p>
        <p className="detail priority">
          Priority: {this.props.destination.priority}
        </p>
        <p className="detail id">ID: {this.props.destination.id}</p>
      </div>
    );
  }
}

export default TripExpertDestinationListItem;
