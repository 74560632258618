import React from "react";
import base, { firebaseApp } from "../../base";
import axios from "axios";

class RequestNew extends React.Component {
  componentDidMount() {
    this.requestDescriptionRef.focus();
  }

  createRequest = event => {
    console.log("starting");
    // Prevent the form from submitting
    event.preventDefault();

    // Prepare the request data from the new request form
    const requestDescription = this.requestDescriptionRef.value;
    if (requestDescription.length === 0 || requestDescription === "") {
      return;
    }

    const uid = firebaseApp.auth().currentUser.uid;
    const timestamp = new Date().getTime();
    const requestData = {
      description: requestDescription,
      createdByUserId: uid,
      lastActivity: timestamp,
      createdAt: timestamp
    };

    base.addToCollection("users/" + uid + "/requests", requestData);

    // Send to Front

    // const payload = {
    //   sender: firebaseApp.auth().currentUser.email,
    //   message: requestDescription
    // };
    // console.log("posting");
    // axios
    //   .post(
    //     "https://us-central1-simple-trips.cloudfunctions.net/postFrontMessage",
    //     payload
    //   )
    //   .then(function(response) {})
    //   .catch(function(error) {
    //     // something broke, log error and send a 500
    //     console.log(error);
    //   });

    console.log("resetting");
    // Reset the request form
    event.currentTarget.reset();
    this.props.history.push("/dashboard");
  };

  render() {
    return (
      <div>
        <div className="requestbox">
          <div className="title">Concierge Request</div>
          <form action="" className="requestnew" onSubmit={this.createRequest}>
            <p className="requestprompt">
              Please describe your request. Include trip dates and destinations,
              if known.
            </p>
            <textarea
              className="requestdescription"
              ref={requestDescriptionRef =>
                (this.requestDescriptionRef = requestDescriptionRef)
              }
              name="requestDescription"
              placeholder="Start typing..."
              type="text"
            />
            <button className="button" type="submit">
              Submit Request
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default RequestNew;
