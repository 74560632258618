import React from "react";
import { Link } from "react-router-dom";

class DestinationListItem extends React.Component {
  render() {
    return (
      <div>
        <div>
          {this.props.pickerCallback ? (
            <button
              className="choose"
              onClick={() => {
                this.props.pickerCallback(this.props.destination);
              }}
            >
              pick
            </button>
          ) : (
            ""
          )}{" "}
          {this.props.destination.name}{" "}
          {this.props.viewLinks ? (
            <Link to={"/manage/destinations/" + this.props.destination.slug}>
              view{" "}
            </Link>
          ) : (
            ""
          )}
          <br />
          <br />
        </div>
      </div>
    );
  }
}

export default DestinationListItem;
