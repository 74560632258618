import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Footer from "../Static/Footer";
import Header from "../Static/Header";
import Callout from "./Elements/Callout";
import PageTitle from "./Elements/PageTitle";

class FAQ extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="pageframe faq overview">
        <Header />
        <Helmet>
          {/* Generic  */}
          <title>Frequently Asked Questions - Simple Trips</title>
          <meta
            name="description"
            content="Simple Trips FAQ - we're here to help!"
          />

          {/* Twitter */}
          <meta
            name="twitter:card"
            value="Simple Trips FAQ - we're here to help!"
          />

          {/* Open Graph */}
          <meta
            property="og:title"
            content="Frequently Asked Questions - Simple Trips"
          />
          <meta property="og:type" content="article" />
          <meta
            property="og:url"
            content="https://simpletrips.com/business-travel"
          />
          <meta
            property="og:image"
            content="https://simpletrips.com/assets/images/meta.png"
          />
          <meta
            property="og:description"
            content="Simple Trips FAQ - we're here to help!"
          />
        </Helmet>

        <PageTitle title="Frequently Asked Questions" subtitle="Simple Trips" />

        <Callout title="What types of services does Simple Trips offer?">
          <p>
            We have several offerings for travel.{" "}
            <Link to="/business-travel">Simple Trips for Business</Link> is
            targted at business travelers. For personal travel, we offer premium
            concierge services and trip advice.
          </p>
        </Callout>

        <Callout title="How can I contact Simple Trips?">
          <p>
            We make it easy to get in touch. Simply visit our{" "}
            <Link to="/contact-us">Contact Us</Link> page to find out how to
            reach us.
          </p>
        </Callout>

        <Footer />
      </div>
    );
  }
}

export default FAQ;
