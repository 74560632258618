import React from "react";

import Footer from "../Static/Footer";
import Header from "../Static/Header";

class LegalPrivacyPolicy extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="pageframe legalpage">
        <Header />

        <div className="wrapper">
          <h3>Privacy Policy</h3>

          <p>
            Protecting your private information is our priority. This Statement
            of Privacy applies to simpletrips.com and Simple Trips, Inc. and
            governs data collection and usage. For the purposes of this Privacy
            Policy, unless otherwise noted, all references to Simple Trips, Inc.
            include simpletrips.com and Simple Trips. The Simple Trips website
            is a Online Travel site. By using the Simple Trips website, you
            consent to the data practices described in this statement.{" "}
          </p>

          <h4>Collection of your Personal Information </h4>
          <p>
            In order to better provide you with products and services offered on
            our Site, Simple Trips may collect personally identifiable
            information such as your:{" "}
          </p>
          <ul>
            <li>First and Last Name</li>
            <li>Mailing Address</li>
            <li>Email Address</li>
            <li>Telephone Numbers</li>
            <li>Payment Information </li>
          </ul>

          <p>
            We do not collect any personal information about you unless you
            voluntarily provide it to us. However, you may be required to
            provide certain personal information to us when you elect to use
            certain products or services available on the Site. These may
            include: (a) registering for an account on our Site; (b) entering a
            sweepstakes or contest sponsored by us or one of our partners; (c)
            signing up for special offers from selected third parties; (d)
            sending us an email message; (e) submitting your credit card or
            other payment information when ordering and purchasing products and
            services on our Site. To wit, we will use your information for, but
            not limited to, communicating with you in relation to services
            and/or products you have requested from us. We also may gather
            additional personal or non-personal information in the future.{" "}
          </p>

          <h4>Use of your Personal Information </h4>
          <p>
            Simple Trips collects and uses your personal information to operate
            its website(s) and deliver the services you have requested.{" "}
          </p>

          <p>
            Simple Trips may also use your personally identifiable information
            to inform you of other products or services available from Simple
            Trips and its affiliates.{" "}
          </p>

          <h4>Sharing Information with Third Parties </h4>
          <p>
            Simple Trips does not sell, rent or lease its customer lists to
            third parties.{" "}
          </p>

          <p>
            Simple Trips may, from time to time, contact you on behalf of
            external business partners about a particular offering that may be
            of interest to you. In those cases, your unique personally
            identifiable information (e-mail, name, address, telephone number)
            is not transferred to the third party. Simple Trips may share data
            with trusted partners to help perform statistical analysis, send you
            email or postal mail, provide customer support, or arrange for
            deliveries. All such third parties are prohibited from using your
            personal information except to provide these services to Simple
            Trips, and they are required to maintain the confidentiality of your
            information.{" "}
          </p>

          <p>
            Simple Trips may disclose your personal information, without notice,
            if required to do so by law or in the good faith belief that such
            action is necessary to: (a) conform to the edicts of the law or
            comply with legal process served on Simple Trips or the site; (b)
            protect and defend the rights or property of Simple Trips; and/or
            (c) act under exigent circumstances to protect the personal safety
            of users of Simple Trips, or the public.{" "}
          </p>

          <h4>Tracking User Behavior </h4>
          <p>
            Simple Trips may keep track of the websites and pages our users
            visit within Simple Trips, in order to determine what Simple Trips
            services are the most popular. This data is used to deliver
            customized content and advertising within Simple Trips to customers
            whose behavior indicates that they are interested in a particular
            subject area.{" "}
          </p>

          <h4>Automatically Collected Information </h4>
          <p>
            Information about your computer hardware and software may be
            automatically collected by Simple Trips. This information can
            include: your IP address, browser type, domain names, access times
            and referring website addresses. This information is used for the
            operation of the service, to maintain quality of the service, and to
            provide general statistics regarding use of the Simple Trips
            website.{" "}
          </p>

          <h4>Use of Cookies </h4>
          <p>
            As you navigate the Service, we may also collect information through
            the use of commonly-used information-gathering tools, such as
            cookies and web beacons (“Web Site Navigational Information”). Web
            Site Navigational Information includes standard information from
            your Web browser (such as browser type and browser language), your
            Internet Protocol (“IP”) address, and the actions you take on the
            Service (such as the web pages viewed and the links clicked).
          </p>

          <h4>Links </h4>
          <p>
            This website contains links to other sites. Please be aware that we
            are not responsible for the content or privacy practices of such
            other sites. We encourage our users to be aware when they leave our
            site and to read the privacy statements of any other site that
            collects personally identifiable information.{" "}
          </p>

          <h4>Security of your Personal Information </h4>
          <p>
            Simple Trips secures your personal information from unauthorized
            access, use, or disclosure. When personal information (such as a
            credit card number) is transmitted to other websites, it is
            protected through the use of encryption, such as the Secure Sockets
            Layer (SSL) protocol.{" "}
          </p>

          <p>
            We strive to take appropriate security measures to protect against
            unauthorized access to or alteration of your personal information.
            Unfortunately, no data transmission over the Internet or any
            wireless network can be guaranteed to be 100% secure. As a result,
            while we strive to protect your personal information, you
            acknowledge that: (a) there are security and privacy limitations
            inherent to the Internet which are beyond our control; and (b)
            security, integrity, and privacy of any and all information and data
            exchanged between you and us through this Site cannot be guaranteed.{" "}
          </p>

          <h4>
            Disconnecting your Simple Trips Account from Third Party Websites{" "}
          </h4>
          <p>
            You will be able to connect your Simple Trips account to third party
            accounts. BY CONNECTING YOUR SIMPLE TRIPS ACCOUNT TO YOUR THIRD
            PARTY ACCOUNT, YOU ACKNOWLEDGE AND AGREE THAT YOU ARE CONSENTING TO
            THE CONTINUOUS RELEASE OF INFORMATION ABOUT YOU TO OTHERS (IN
            ACCORDANCE WITH YOUR PRIVACY SETTINGS ON THOSE THIRD PARTY SITES).
            IF YOU DO NOT WANT INFORMATION ABOUT YOU, INCLUDING PERSONALLY
            IDENTIFYING INFORMATION, TO BE SHARED IN THIS MANNER, DO NOT USE
            THIS FEATURE. You may disconnect your account from a third party
            account at any time. Users may learn how to disconnect their
            accounts from third-party websites by visiting their "My Account"
            page. Users may also contact us via email or telephone.{" "}
          </p>

          <h4>E-mail Communications </h4>
          <p>
            From time to time, Simple Trips may contact you via email for the
            purpose of providing announcements, promotional offers, alerts,
            confirmations, surveys, and/or other general communication. In order
            to improve our Services, we may receive a notification when you open
            an email from Simple Trips or click on a link therein.{" "}
          </p>

          <p>
            If you would like to stop receiving marketing or promotional
            communications via email from Simple Trips, you may opt out of such
            communications by clicking on the UNSUBSCRIBE button..{" "}
          </p>

          <h4>External Data Storage Sites </h4>
          <p>
            We may store your data on servers provided by third party hosting
            vendors with whom we have contracted.{" "}
          </p>

          <h4>Changes to this Statement </h4>
          <p>
            Simple Trips reserves the right to change this Privacy Policy from
            time to time. We will notify you about significant changes in the
            way we treat personal information by sending a notice to the primary
            email address specified in your account, by placing a prominent
            notice on our site, and/or by updating any privacy information on
            this page. Your continued use of the Site and/or Services available
            through this Site after such modifications will constitute your: (a)
            acknowledgment of the modified Privacy Policy; and (b) agreement to
            abide and be bound by that Policy.{" "}
          </p>

          <h4>Contact Information </h4>
          <p>
            Simple Trips welcomes your questions or comments regarding this
            Statement of Privacy. If you believe that Simple Trips has not
            adhered to this Statement, please contact Simple Trips at:{" "}
          </p>

          <p>
            Simple Trips, Inc. <br />
            917 W WASHINGTON BLVD STE 215 <br />
            CHICAGO, ILLINOIS 60607{" "}
          </p>

          <p>Email Address: legal@simpletrips.com </p>

          <p>Telephone number: 844-707-8747 </p>

          <p>Effective as of June 01, 2018 </p>
        </div>

        <Footer />
      </div>
    );
  }
}

export default LegalPrivacyPolicy;
