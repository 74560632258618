import Rebase from "re-base";
import Firebase from "firebase";
import "firebase/firestore";

// const configStaging = {
//   apiKey: "AIzaSyBH2NvKwtIyDD2hmpxQbjq8YMlFDx5Qx0I",
//   authDomain: "simple-trips-staging.firebaseapp.com",
//   databaseURL: "https://simple-trips-staging.firebaseio.com",
//   projectId: "simple-trips-staging",
//   storageBucket: "simple-trips-staging.appspot.com",
//   messagingSenderId: "1082365774260"
// };

const config = {
  apiKey: "AIzaSyBdwTHLrZ7bcc4Ilrn1OGusrsGa8DbYUdM",
  authDomain: "simple-trips.firebaseapp.com",
  databaseURL: "https://simple-trips.firebaseio.com",
  projectId: "simple-trips",
  storageBucket: "simple-trips.appspot.com",
  messagingSenderId: "571112164478"
};

const firebaseApp = Firebase.initializeApp(config);

const db = firebaseApp.firestore(firebaseApp);

const base = Rebase.createClass(db);

export { firebaseApp };

export default base;
