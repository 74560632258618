import React from "react";
import base from "../../base";
import HotelListItem from "./HotelListItem";

class HotelList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hotels: []
    };
  }

  componentWillMount() {
    base.bindCollection("hotels", {
      context: this,
      state: "hotels",
      withRefs: true,
      withIds: true,
      query: ref => {
        return ref
          .where("lastActivity", ">", 0)
          .orderBy("lastActivity", "desc");
      }
    });
  }

  render() {
    var hotels = this.state.hotels.map((hotel, index) => {
      return <HotelListItem hotel={hotel} key={index} viewLinks={true} />;
    });
    return <div>{hotels}</div>;
  }
}

export default HotelList;
