import React from "react";
import { Link } from "react-router-dom";

class DestinationHotelItem extends React.Component {
  render() {
    return (
      <div>
        <div>
          {this.props.hotel.name}{" "}
          {this.props.viewLinks ? (
            <Link to={"/manage/hotels/" + this.props.hotel.slug}>view </Link>
          ) : (
            ""
          )}
          <br />
          <br />
        </div>
      </div>
    );
  }
}

export default DestinationHotelItem;
