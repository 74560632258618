import React from "react";

class HotelSearchEANItem extends React.Component {
  render() {
    return (
      <div>
        <div>
          {this.props.hotel.name}
          <br />
          {this.props.hotel.latitude}, {this.props.hotel.longitude}
          <br />
          EAN ID: {this.props.hotel.hotelId}
          <br />
          {this.props.hotel.address1 ? this.props.hotel.address1 : ""}{" "}
          {this.props.hotel.address2 ? this.props.hotel.address2 + "," : ","}{" "}
          {this.props.hotel.city ? this.props.hotel.city : ""}{" "}
          {this.props.hotel.stateProvinceCode
            ? this.props.hotel.stateProvinceCode
            : ""}
          {", "}
          {this.props.hotel.postalCode ? this.props.hotel.postalCode : ""}{" "}
          <br />
          {this.props.hotel.countryCode
            ? this.props.hotel.countryCode
            : ""}{" "}
          <br />
          <br />
          <br />
        </div>
      </div>
    );
  }
}

export default HotelSearchEANItem;
