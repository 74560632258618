import React from "react";
import axios from "axios";
import base from "../base";
import { Helmet } from "react-helmet";

import Header from "./Static/Header";
import Footer from "./Static/Footer";
import HotelExpertReviews from "./HotelExpertReviews";
import HotelGoogleReviews from "./HotelGoogleReviews";
import HotelAmenities from "./HotelAmenities";
import HotelMap from "./HotelMap";
import Loading from "./Static/Loading";

class Hotel extends React.Component {
  state = {
    hotel: null,
    eanHotel: null,
    error: null
  };

  getDetails = () => {
    base
      .get("hotels", {
        context: this,
        withIds: true,
        query: ref => ref.where("slug", "==", this.props.match.params.slug)
      })
      .then(data => {
        this.setState({ hotel: data[0] });
        this.getEanDetails();
      })
      .catch(err => {
        //handle error
        this.props.history.push("/notfound");
      });
  };

  getEanDetails = () => {
    const hotelUrl =
      "https://us-central1-simple-trips.cloudfunctions.net/eanHotelDetail?id=" +
      this.state.hotel.idEan;
    axios.get(hotelUrl).then(result => {
      this.setState({
        eanHotel: result.data.data.HotelInformationResponse
      });
    });
  };

  componentWillMount() {
    this.getDetails();
  }

  render() {
    return this.state.hotel == null || this.state.eanHotel == null ? (
      <div>
        <Header hideLinks={true} />
        <Loading />
        <Footer />
      </div>
    ) : (
        <div>
          <Helmet>
            <title>{this.state.hotel.name + " - Simple Trips"}</title>
            <meta property="og:title" content={this.state.hotel.name} />
            <meta
              property="og:description"
              content={"Check out " + this.state.hotel.name + " on Simple Trips."}
            />
            {this.state.eanHotel.HotelImages.HotelImage.length > 0 ? (
              <meta
                property="og:image"
                content={
                  this.state.eanHotel.HotelImages.HotelImage[0].highResolutionUrl
                }
              />
            ) : (
                ""
              )}
          </Helmet>
          <Header hideLinks={true} />
          <div className="wrapper page hotel">
            <h1>{this.state.hotel.name}</h1>
            <h2 className="styled">Overview</h2>
            <div
              className="detail"
              id="hotel-description"
              dangerouslySetInnerHTML={{
                __html: this.state.eanHotel.HotelDetails.locationDescription
              }}
            />

            <h2 className="styled">Contact Information</h2>
            <p className="detail">{this.state.hotel.address}</p>
            <p className="detail">{this.state.hotel.phone}</p>

            <h2 className="styled">Photos</h2>

            {this.state.eanHotel.HotelImages.HotelImage.map((image, index) => {
              return (
                <div
                  className="hotelimageitem"
                  key={index}
                  style={{
                    backgroundImage: "url(" + image.highResolutionUrl + ")"
                  }}
                >
                  {/* <img
                    src={image.highResolutionUrl}
                    key={index}
                    alt={image.name}
                  /> */}
                </div>
              );
            })}
            <div className="imagecontrols">
              <button
                type="button"
                className="imagecontrolbutton prev"
                onClick={() => {
                  this.reactSwipe.prev();
                }}
              >
                <i className="fas fa-arrow-left" />
              </button>
              <button
                type="button"
                className="imagecontrolbutton next"
                onClick={() => {
                  this.reactSwipe.next();
                }}
              >
                <i className="fas fa-arrow-right" />
              </button>
              <div className="clear" />
            </div>
            <div className="clear" />

            <h2 className="styled">Expert Reviews</h2>
            <HotelExpertReviews hotel={this.state.hotel} />

            <h2 className="styled">Public Opinion</h2>
            <HotelGoogleReviews hotel={this.state.hotel} />

            <h2 className="styled">Map</h2>
            <HotelMap hotel={this.state.hotel} />

            <h2 className="styled">Amenities</h2>
            <HotelAmenities
              amenities={this.state.eanHotel.PropertyAmenities.PropertyAmenity}
            />
          </div>
          <Footer />
        </div>
      );
  }
}

export default Hotel;
