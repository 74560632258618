import React from "react";
import axios from "axios";

class UnsplashViewer extends React.Component {
  state = {
    loading: true,
    images: []
  };

  fetchImages = id => {
    var url =
      "https://api.unsplash.com/search/photos" +
      "?client_id=7d6e2df2990832f654ec211155b3fb48a0cc3a84ae6ddb458fae935d50fe6548" +
      "&per_page=50" +
      "&query=" +
      this.props.query;
    axios.get(url).then(result => {
      this.setState({
        loading: false,
        images: result.data.results
      });
    });
  };

  download = key => {
    const file = this.state.images[key].urls.regular;
    window.open(file);
  };

  componentDidMount() {
    this.fetchImages();
  }

  render() {
    return (
      <div className="unsplashviewer">
        <p>Unsplash Photos</p>
        <p className="query">{this.props.query}</p>
        <div className="results">
          {Object.keys(this.state.images).map(key => (
            <div
              className="image-result"
              key={key}
              onClick={() => this.download(key)}
            >
              <img
                src={this.state.images[key].urls.small}
                alt={this.props.query}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default UnsplashViewer;
