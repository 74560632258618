import React from "react";
import {
  createAccount,
  authenticateWithFacebook,
  authenticateWithGoogle
} from "../../helpers/auth";
import { Link } from "react-router-dom";

import Footer from "../Static/Footer";
import Header from "../Static/Header";
import LoadingSimple from "../Static/LoadingSimple";

class CreateAccount extends React.Component {
  state = {
    errorMessage: null,
    loading: false
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  performSignup = event => {
    event.preventDefault();
    this.setState({ loading: true });
    const email = this.emailRef.value;
    const password = this.passwordRef.value;
    createAccount(email, password).catch(error => {
      var simpleMessage = "";
      switch (error.code) {
        case "auth/invalid-email": {
          simpleMessage = "Please enter a valid email address.";
          break;
        }
        case "auth/operation-not-allowed": {
          simpleMessage = "Sign up is disabled.";
          break;
        }
        case "auth/weak-password": {
          simpleMessage = "Please enter a more secure password.";
          break;
        }
        case "auth/email-already-in-use": {
          simpleMessage =
            "There is already an account with this email address.";
          break;
        }
        default: {
          simpleMessage = "There was a problem. Please try again.";
        }
      }
      this.setState({ errorMessage: simpleMessage, loading: false });
    });
  };

  performFacebookAuth = () => {
    authenticateWithFacebook();
  };

  performGoogleAuth = () => {
    authenticateWithGoogle();
  };

  render() {
    return (
      <div className="pageframe signup">
        <Header />

        <div className="authbox">
          <div className="title">Create an account</div>
          {this.state.loading ? (
            <div className="spacing">
              <LoadingSimple />
            </div>
          ) : (
            <div>
              {this.state.errorMessage ? (
                <div className="errorbox">
                  <i className="fas fa-exclamation-circle erroricon" />
                  <p className="errormessage">{this.state.errorMessage}</p>
                  <div className="clear" />
                </div>
              ) : (
                ""
              )}
              <form action="" className="signup" onSubmit={this.performSignup}>
                <input
                  className="login"
                  ref={emailRef => (this.emailRef = emailRef)}
                  name="email"
                  placeholder="Email"
                  type="email"
                />
                <input
                  className="login"
                  ref={passwordRef => (this.passwordRef = passwordRef)}
                  name="password"
                  placeholder="Choose a password"
                  type="password"
                />
                <button className="button login traditional" type="submit">
                  Continue
                </button>
              </form>

              <div className="divider" />

              <button
                className="button login facebook"
                onClick={this.performFacebookAuth}
              >
                <i className="fab fa-facebook-f authicon" />Sign up with
                Facebook
              </button>
              <button
                className="button login google"
                onClick={this.performGoogleAuth}
              >
                <i className="fab fa-google authicon" />Sign up with Google
              </button>
            </div>
          )}
        </div>

        <div className="authswitchbox">
          <div className="title">
            <Link to="/login">Already have an account?</Link>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default CreateAccount;
