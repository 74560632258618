import React from "react";
import TopBar from "./TopBar";
import RequestList from "./RequestList";

class Dashboard extends React.Component {
  render() {
    return (
      <div className="app-page dashboard">
        <TopBar />
        <div className="main-window">
          <div className="container">
            <RequestList />
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
