import React, { Component } from "react";
import { ThreeBounce } from "better-react-spinkit";

class Loading extends Component {
  render() {
    return (
      <div>
        <div className="wrapper">
          <div className="splashloader">
            <ThreeBounce size={15} color="#396af1" />
          </div>
        </div>
      </div>
    );
  }
}

export default Loading;
