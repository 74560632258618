import React from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import DestinationList from "./DestinationList";
import HotelList from "./HotelList";

class Manage extends React.Component {
  render() {
    return (
      <div className="management">
        <Navbar />
        <div className="mainpanel">
          <div className="subpanel">
            <h3>Destinations</h3>
            <div className="destinations">
              <Link to="/manage/destinations/new" className="listaction">
                <i className="fas fa-plus-square" /> Add Destination
              </Link>
              <br />
              <Link to="/manage/destinations" className="listaction">
                <i className="fas fa-list" /> View All
              </Link>
            </div>
            <br />
            <br />
            <DestinationList />
          </div>

          <div className="subpanel">
            <h3>Hotels</h3>
            <div className="hotels">
              <Link to="/manage/hotels/new" className="listaction">
                <i className="fas fa-plus-square" /> Add Hotel
              </Link>
              <br />
              <Link to="/manage/hotels" className="listaction">
                <i className="fas fa-list" /> View All
              </Link>
            </div>
            <br />
            <br />
            <HotelList />
          </div>
        </div>
      </div>
    );
  }
}

export default Manage;
