import React from "react";
import axios from "axios";
import TripExpertDestinationListItem from "./TripExpertDestinationListItem";
import { ThreeBounce } from "better-react-spinkit";

class TripExpertDestinationList extends React.Component {
  state = {
    destinations: [],
    loading: false,
    showResults: false
  };

  fetchDestinationList = () => {
    this.setState({ loading: true });
    const url =
      "https://us-central1-simple-trips.cloudfunctions.net/tripExpertDestinations?api_key=50295020f3bb8aeaf70eabe370603e4c" +
      "&order_by=priority";
    console.log(url);
    axios.get(url).then(result => {
      console.log(result);
      this.setState({
        destinations: result.data.response.destinations,
        loading: false
      });
    });
  };

  render() {
    return this.state.loading === true ? (
      <ThreeBounce size={15} color="#396af1" />
    ) : (
      <div className="tripexpertdestinationlist">
        {this.state.showResults ? (
          <div>
            {Object.keys(this.state.destinations).map(key => (
              <TripExpertDestinationListItem
                destination={this.state.destinations[key]}
                key={key}
                selectFrom={this.props.selectFrom}
              />
            ))}
          </div>
        ) : (
          <button
            className="general"
            onClick={() => {
              this.fetchDestinationList();
              this.setState({ showResults: true });
            }}
          >
            Show Trip Expert Destinations
          </button>
        )}
      </div>
    );
  }
}

export default TripExpertDestinationList;
