import React from "react";

class Feature extends React.Component {
  render() {
    return (
      <div className="wrapper">
        <div className="feature">
          <div className="featureinner">
            <div className={"graphic " + this.props.position}>
              <img src={this.props.graphic} alt={this.props.title} />
            </div>
            <div className="description">
              {this.props.title ? (
                <h4 className="styled">{this.props.title}</h4>
              ) : (
                ""
              )}
              {this.props.description ? <p>{this.props.description}</p> : ""}
            </div>
          </div>
        </div>
        <div className="clear" />
      </div>
    );
  }
}

export default Feature;
