import React from "react";
import base from "../../base";
import Navbar from "./Navbar";
import UnsplashViewer from "./UnsplashViewer";
import DestinationPhoto from "./DestinationPhoto";
import { ThreeBounce } from "better-react-spinkit";

class DestinationDetail extends React.Component {
  state = {
    destination: null,
    loading: true,
    showUnsplash: false,
    showPhotoEdit: false
  };

  componentWillMount() {
    this.getDetails();
  }

  getDetails = () => {
    base
      .get("destinations", {
        context: this,
        withIds: true,
        query: ref => ref.where("slug", "==", this.props.match.params.slug)
      })
      .then(data => {
        this.setState({ destination: data[0], loading: false });
      })
      .catch(error => {
        console.log(error);
      });
  };

  updateCoverPhoto = url => {
    const data = { coverPhotoUrl: url };
    base
      .updateDoc("destinations/" + this.state.destination.id, data)
      .then(() => {
        //document is updated
        this.setState({
          loading: true,
          showPhotoEdit: false,
          showUnsplash: false
        });
        this.getDetails();
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    return (
      <div className="management">
        <Navbar />
        <div className="mainpanel">
          {this.state.loading ? (
            <ThreeBounce size={15} color="#396af1" />
          ) : (
            <div>
              <h1>{this.state.destination.name}</h1>

              {this.state.destination.coverPhotoUrl ? (
                <div className="coverphotodetails">
                  <p>Cover Photo</p>
                  <img
                    className="coverphoto"
                    src={this.state.destination.coverPhotoUrl}
                    alt={this.state.destination.name}
                  />
                </div>
              ) : (
                ""
              )}

              {this.state.showPhotoEdit ? (
                <div>
                  <DestinationPhoto callback={this.updateCoverPhoto} />
                  <br />
                  <br />
                  {this.state.showUnsplash ? (
                    <UnsplashViewer query={this.state.destination.name} />
                  ) : (
                    <button
                      className="general"
                      onClick={() => {
                        this.setState({ showUnsplash: true });
                      }}
                    >
                      Show Unsplash Photos
                    </button>
                  )}
                </div>
              ) : (
                <button
                  className="general"
                  onClick={() => {
                    this.setState({ showPhotoEdit: true });
                  }}
                >
                  Change Photo
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default DestinationDetail;
