import React from "react";
import firebase from "firebase";

class DestinationPhoto extends React.Component {
  handleUploadSuccess = filename => {
    firebase
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL()
      .then(url => this.props.callback(url));
  };

  render() {
    return (
      <div className="destinationphoto">
        nothing now
      </div>
    );
  }
}

export default DestinationPhoto;
