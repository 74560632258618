import React, { Component } from "react";
import base from "../base";

import Header from "./Static/Header";
import Footer from "./Static/Footer";
import Loading from "./Static/Loading";
import DestinationHotelItem from "./DestinationHotelItem";

class Destination extends Component {
  state = {
    destination: null,
    hotels: null
  };

  componentWillMount() {
    if (this.props.match.params.slug !== undefined) {
      this.getDestination(this.props.match.params.slug);
    }
  }

  getDestination = slug => {
    // Finds the destination by slug and sets it as our destination in state
    base
      .get("destinations", {
        context: this,
        withIds: true,
        query: ref => ref.where("slug", "==", slug)
      })
      .then(data => {
        this.setState({ destination: data[0] });
        this.getHotels();
      })
      .catch(err => {
        //handle error
        this.props.history.push("/notfound");
      });
  };

  getHotels = () => {
    // Fetches hotels from the state's destination ID
    base
      .get("hotels", {
        context: this,
        withIds: true,
        query: ref =>
          ref.where("destinationId", "==", this.state.destination.id)
      })
      .then(data => {
        this.setState({ hotels: data });
      });
  };

  render() {
    return this.state.hotels == null ? (
      <div>
        <Header />
        <Loading />
        <Footer />
      </div>
    ) : (
      <div>
        <Header />
        <div className="wrapper page">
          <div className="content">
            <h1>Hotels in {this.state.destination.name}</h1>
            {Object.keys(this.state.hotels).map(key => (
              <DestinationHotelItem
                key={key}
                index={key}
                hotel={this.state.hotels[key]}
              />
            ))}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Destination;
