import React from "react";
import TopBar from "./TopBar";
import RequestNew from "./RequestNew";

class RequestNewPage extends React.Component {
  render() {
    return (
      <div className="app-page requestnew">
        <TopBar />
        <div className="main-window">
          <div className="container">
            <RequestNew history={this.props.history} />
          </div>
        </div>
      </div>
    );
  }
}

export default RequestNewPage;
