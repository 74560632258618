import React from "react";
import { Link } from "react-router-dom";

class Navbar extends React.Component {
  render() {
    return (
      <div className="navbar">
        {/* Left logo branding and link to root page */}
        <div className="branding">
          <Link to="/manage">
            <img
              src="/assets/images/logo.png"
              width="24"
              alt="Simple Trips Logo"
            />
          </Link>
        </div>

        {/* Right navigation */}
        <ul className="toplinks">
          <li className="linkitem">
            <Link to="/manage" className="link">
              Manage
            </Link>
          </li>
          <li className="linkitem">
            <Link to="/manage/info" className="link">
              <i className="fas fa-info-circle" />
            </Link>
          </li>
          <li className="linkitem">
            <Link to="/" className="link" target="_blank">
              <i className="fas fa-external-link-alt" />
            </Link>
          </li>
        </ul>

        {/* Clear - fixes box parent */}
        <div className="clear" />
      </div>
    );
  }
}

export default Navbar;
