import React from "react";
import axios from "axios";
import base from "../../base";

import { ThreeBounce } from "better-react-spinkit";

class TripExpertHotelPickerItem extends React.Component {
  state = {
    venue: null,
    mapping: null,
    travelPayoutsId: null
  };

  select = () => {
    if (!this.state.mapping) {
      alert("fetch mapping first");
      return;
    }
    this.props.pickerCallback(
      this.state.venue,
      this.state.mapping,
      this.state.travelPayoutsId
    );
  };

  view = () => {
    const url = "https://www.tripexpert.com/" + this.state.venue.path;
    var win = window.open(url, "_blank");
    win.focus();
  };

  fetchDetailedInfo = id => {
    var hotelUrl =
      "https://us-central1-simple-trips.cloudfunctions.net/tripExpertVenueDetails" +
      "?api_key=50295020f3bb8aeaf70eabe370603e4c" +
      "&venue_id=" +
      id;

    axios.get(hotelUrl).then(result => {
      this.setState({ venue: result.data.response.venues[0] });
    });
  };

  fetchMap = id => {
    base
      .get("hotelMapping/by/tripExpert/" + id, {
        context: this
      })
      .then(data => {
        //do something with data
        console.log(data);
        this.setState({ mapping: data, fetchingMapping: false });
        if (data.bookingDotComId) {
          this.fetchTravelPayouts(data.bookingDotComId);
        }
      })
      .catch(err => {
        //handle error
        console.log(err);
      });
  };

  fetchTravelPayouts = idBookingDotCom => {
    this.setState({ fetchingTpId: true });
    const hotelUrl =
      "https://simpletrips-data-api.herokuapp.com/hotel-look-hotels?id-booking-dot-com=" +
      idBookingDotCom;
    axios.get(hotelUrl).then(result => {
      if (result.data.length > 0) {
        this.setState({ travelPayoutsId: result.data[0]["id-travelpayouts"] });
      } else {
        this.setState({ travelPayoutsId: "none" });
      }
      this.setState({ fetchingTpId: false });
    });
  };

  componentWillMount() {
    this.fetchDetailedInfo(this.props.hotel.id);
  }

  render() {
    return (
      <div className="item">
        <p className="detail name">{this.props.hotel.name} </p>
        <p className="detail stars price">
          {/* Core Venue Info: Star Rating, Low Rate */}
          {this.state.venue
            ? this.state.venue.star_rating +
              " stars, from $" +
              this.state.venue.low_rate
            : ""}
        </p>

        <button
          className="choose"
          onClick={() => {
            this.view();
          }}
        >
          <i className="fas fa-arrow-right" /> View
        </button>
        <p className="detail id">Trip Expert ID: {this.props.hotel.id}</p>

        {this.state.fetchingMapping ? (
          <ThreeBounce size={10} color="#396af1" />
        ) : (
          <div>
            {this.state.mapping ? (
              <div className="mapping">
                <button
                  className="choose"
                  onClick={() => {
                    this.select();
                  }}
                >
                  <i className="fas fa-check" /> Select
                </button>
                <p className="detail mapping">
                  {/* ID Numbers */}
                  {this.state.mapping
                    ? "EAN: " +
                      this.state.mapping.eanId +
                      " Booking: " +
                      this.state.mapping.bookingDotComId +
                      " Expedia: " +
                      this.state.mapping.expediaId
                    : "..."}
                  <br />
                  {/* LAT/LON */}
                  {this.state.mapping
                    ? "Location: " +
                      this.state.mapping.latitude +
                      ", " +
                      this.state.mapping.longitude
                    : ""}
                  <br />
                  {/* Street Address */}
                  {this.state.mapping
                    ? "Address: " + this.state.mapping.address
                    : ""}
                  <br />
                  {/* Phone */}
                  {this.state.mapping
                    ? "Phone Number: " + this.state.mapping.telephone
                    : ""}
                  <br />
                  {this.state.venue
                    ? "Website: " + this.state.venue.website
                    : ""}
                  <br />
                  {/* TP ID */}
                  {this.state.travelPayoutsId
                    ? "TP ID: " + this.state.travelPayoutsId
                    : "..."}
                </p>
              </div>
            ) : (
              <button
                className="choose"
                onClick={() => {
                  this.fetchMap(this.props.hotel.id);
                  this.setState({ fetchingMapping: true });
                }}
              >
                Check Mappings
              </button>
            )}
          </div>
        )}

        <br />
        <br />
      </div>
    );
  }
}

export default TripExpertHotelPickerItem;
