import React from "react";
import Footer from "../Static/Footer";
import Header from "../Static/Header";
import Callout from "./Elements/Callout";
import PageTitle from "./Elements/PageTitle";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ContactUs extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="pageframe contact overview">
        <Header />
        <Helmet>
          {/* Generic  */}
          <title>
            Contact Simple Trips - Help and Support - Travel made easy
          </title>
          <meta
            name="description"
            content="Contact Simple Trips - we're here to help!"
          />

          {/* Twitter */}
          <meta
            name="twitter:card"
            value="Contact Simple Trips - we're here to help!"
          />

          {/* Open Graph */}
          <meta
            property="og:title"
            content="Contact Simple Trips - we're here to help!"
          />
          <meta property="og:type" content="article" />
          <meta
            property="og:url"
            content="https://simpletrips.com/business-travel"
          />
          <meta
            property="og:image"
            content="https://simpletrips.com/assets/images/meta.png"
          />
          <meta
            property="og:description"
            content="Contact Simple Trips - we're here to help!"
          />
        </Helmet>

        <PageTitle title="Contact Us" subtitle="Simple Trips" />

        <Callout title="Send an email" description="hello@simpletrips.com" />

        <Callout title="Give us a call">
          <p>
            Toll Free: (844) 707-8747 <br />
            Local: (312) 487-3010
          </p>
        </Callout>

        <Callout title="Visit us on Social Media">
          <p>
            <a
              href="http://facebook.com/SimpleTripsTravel"
              className="social_icon facebook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={["fab", "facebook-f"]} size="lg" />{" "}
            </a>
            <a
              href="http://m.me/SimpleTripsTravel"
              className="social_icon facebookmessenger"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={["fab", "facebook-messenger"]} size="lg" />{" "}
            </a>
            <a
              href="http://instagram.com/SimpleTripsTravel"
              className="social_icon instagram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={["fab", "instagram"]} size="lg" />{" "}
            </a>
            <a
              href="https://www.linkedin.com/company/simple-trips/"
              className="social_icon linkedin"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={["fab", "linkedin-in"]} size="lg" />
            </a>
            <a
              href="http://twitter.com/askSimpleTrips"
              className="social_icon twitter"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={["fab", "twitter"]} size="lg" />
            </a>

            <a
              href="https://www.youtube.com/channel/UCJj-gTbtK_kWVzuHmSHscFg"
              className="social_icon youtube"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={["fab", "youtube"]} size="lg" />
            </a>
            <a
              href="https://medium.com/simple-trips"
              className="social_icon medium"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={["fab", "medium-m"]} size="1x" />
            </a>
          </p>
        </Callout>

        <Callout title="Send physical mail">
          <p>
            Simple Trips, Inc.
            <br />
            917 W Washington Blvd. <br />
            Suite 215
            <br />
            Chicago, IL 60607
          </p>
        </Callout>

        <Footer />
      </div>
    );
  }
}

export default ContactUs;
