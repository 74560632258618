import React from "react";
import Footer from "../Static/Footer";
import Header from "../Static/Header";
import { Helmet } from "react-helmet";

class FreeConsultationConfirmation extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="pageframe consultation confirmation">
        <Header />
        <Helmet>
          {/* Generic  */}
          <title>Your consultation is confirmed - Simple Trips</title>
          <meta
            name="description"
            content="We offer a free consultation to help understand your needs as a client."
          />
        </Helmet>

        <div className="wrapper">
          <div className="pagetitlecontainer">
            <h1>
              Your appointment has been confirmed. We're looking forward to it!
            </h1>
          </div>

          <div className="clear" />
        </div>

        <Footer />
      </div>
    );
  }
}

export default FreeConsultationConfirmation;
