import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Footer from "../Static/Footer";
import Header from "../Static/Header";
import Callout from "./Elements/Callout";
import PageTitle from "./Elements/PageTitle";

class Help extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  frameContent = () => {
    const iframe = {
      __html:
        '<iframe class="scheduler_frame" src="https://stcontactform.carrd.co" width="100%" height="550"></iframe>'
    };
    return iframe;
  };

  render() {
    return (
      <div className="pageframe help overview">
        <Header />
        <Helmet>
          {/* Generic  */}
          <title>Help Center - Simple Trips</title>
          <meta name="description" content="We're here to help." />

          {/* Twitter */}
          <meta name="twitter:card" value="We're here to help." />

          {/* Open Graph */}
          <meta property="og:title" content="Help Center - Simple Trips" />
          <meta property="og:type" content="article" />
          <meta
            property="og:url"
            content="https://simpletrips.com/business-travel"
          />
          <meta
            property="og:image"
            content="https://simpletrips.com/assets/images/meta.png"
          />
          <meta
            property="og:description"
            content="Help Center - Simple Trips"
          />
        </Helmet>

        <PageTitle title="Help Center" subtitle="Simple Trips" />

        <Callout description="We're here to help - it's part of our guarantee." />

        <Callout title="Help during a trip">
          <p>
            For immediate assistance, please{" "}
            <Link to="/contact-us">contact us</Link> or email your concierge.
          </p>
        </Callout>

        <Callout title="Frequently Asked Questions">
          <p>
            Browse answers to common questions in our <Link to="/faq">FAQ</Link>
            .
          </p>
        </Callout>

        <PageTitle title="Send us a message" />

        <div className="personaldemo">
          <div dangerouslySetInnerHTML={this.frameContent()} />
        </div>

        <Footer />
      </div>
    );
  }
}

export default Help;
