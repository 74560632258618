import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Footer from "../Static/Footer";
import Header from "../Static/Header";
import Callout from "./Elements/Callout";
import Feature from "./Elements/Feature";
import PageTitle from "./Elements/PageTitle";

class PersonalTravel extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="pageframe personal overview">
        <Header />
        <Helmet>
          {/* Generic  */}
          <title>Simple Trips - Budget or luxury. Leisure or pleasure.</title>
          <meta
            name="description"
            content="Do you love to travel, but hate spending hours putting together the perfect trip? Simple Trips is for you. We offer personal travel services tailored to your needs."
          />

          {/* Twitter */}
          <meta
            name="twitter:card"
            value="Do you love to travel, but hate spending hours putting together the perfect trip? Simple Trips is for you. We offer personal travel services tailored to your needs."
          />

          {/* Open Graph */}
          <meta
            property="og:title"
            content="Simple Trips - Budget or luxury. Leisure or pleasure."
          />
          <meta property="og:type" content="article" />
          <meta
            property="og:url"
            content="https://simpletrips.com/personal-travel"
          />
          <meta
            property="og:image"
            content="https://simpletrips.com/assets/images/meta.png"
          />
          <meta
            property="og:description"
            content="Do you love to travel, but hate spending hours putting together the perfect trip? Simple Trips is for you. We offer personal travel services tailored to your needs."
          />
        </Helmet>

        <PageTitle
          title="Simple Trips"
          subtitle="Budget or luxury. Leisure or pleasure."
        />

        <Callout description="Do you love to travel, but hate spending hours putting together the perfect trip? Simple Trips is for you. We offer personal travel services tailored to your needs." />

        <Feature
          title="Premium Concierge"
          description="Our concierge is available to you 24/7. Real and experienced travel agents are here to answer any question you may have. We can help make modifications, book restaurant reservations, and more."
          position="right"
          graphic="/assets/svg/undraw.departing.svg"
        />

        <Feature
          title="Best Rates. Guaranteed."
          description="We have access to direct rates from travel providers, and we compare these rates to every other rate online to ensure you get the best price every time."
          position="left"
          graphic="/assets/svg/undraw.designer.svg"
        />

        <Feature
          title="Exclusive Benefits"
          description="With Simple Trips, you are entitled to premium benefits at many properties when you stay. Enjoy free spa credits, included dining, and more with select reservations."
          position="right"
          graphic="/assets/svg/undraw.beach.svg"
        />

        <Feature
          title="Personalized Service"
          description="We get to know you and your preferences. We combine your tastes and travel habits to create unique recommendations every time."
          position="left"
          graphic="/assets/svg/undraw.personalization.svg"
        />

        <PageTitle title="What can Simple Trips do?" />

        <div className="wrapper examples">
          <h3>Research hotels</h3>
          <p className="featureexample">
            Where is the <span>most affordable</span> hotel with{" "}
            <span>3 stars</span> in <span>Austin</span>?
          </p>
          <p className="featureexample">
            Should I use <span>points</span> or <span>pay cash</span> for my
            trip to <span>San Francisco</span>?
          </p>
          <p className="featureexample">
            Is a <span>hotel</span> or <span>vacation rental</span> better for
            my trip?
          </p>
        </div>

        <div className="wrapper examples">
          <h3>Search for a flight</h3>
          <p className="featureexample">
            I need a flight from <span>Chicago</span> to <span>Denver</span> on{" "}
            <span>April 19th</span>.
          </p>
          <p className="featureexample">
            When is the <span>cheapest</span> time to fly to{" "}
            <span>Hong Kong</span> from <span>New York</span>?
          </p>
          <p className="featureexample">
            Can I use my <span>miles</span> to fly to <span>London</span> in the{" "}
            <span>fall</span>?
          </p>
        </div>

        <div className="wrapper examples">
          <h3>Find a great deal</h3>
          <p className="featureexample">
            Can you send me some <span>flight deals</span> from{" "}
            <span>San Francisco</span>?
          </p>
          <p className="featureexample">
            Should I book <span>now</span> or <span>wait</span> for a better{" "}
            <span>price</span>?
          </p>
          <p className="featureexample">
            I found a flight, is it the <span>best price</span> out there?
          </p>
        </div>

        <Callout
          title="Give it a try."
          description="It all starts with a conversation. Call, text, or chat us to get started. You’ll talk with a real person who is your personal travel concierge. If you don’t know exactly what you want, no problem. We’ll fill in the gaps and you’ll be treated like a friend along the way."
        >
          <Link to="/personal-travel/start" className="button regular">
            Try it now
          </Link>
        </Callout>
        <Footer />
      </div>
    );
  }
}

export default PersonalTravel;
