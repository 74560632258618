import React, { Component } from "react";

class ListHotelItem extends Component {
  state = {
    venue: null,
    mapping: null,
    travelPayoutsId: null
  };

  componentWillMount() {}

  render() {
    return (
      <div>
        <h4 className="styled">{this.props.hotel.name}</h4>
        <p>
          {/* Core Venue Info: Star Rating, Low Rate */}
          {this.props.hotel.starRating
            ? this.props.hotel.starRating + " stars"
            : ""}
          <br />
        </p>
        <br />
      </div>
    );
  }
}

export default ListHotelItem;
