import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Footer from "../Static/Footer";
import Header from "../Static/Header";
import BlogRecent from "./Elements/BlogRecent";

class Landing extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="landing">
        <Header />
        <Helmet>
          {/* Generic  */}
          <title>Simple Trips - we make travel easy.</title>
          <meta
            name="description"
            content="We take the pain out of researching, planning, and booking travel. We're real people with super powers thanks to modern technology and artificial intelligence. "
          />

          {/* Twitter */}
          <meta name="twitter:card" value="summary" />

          {/* Open Graph */}
          <meta
            property="og:title"
            content="Simple Trips - we make travel easy."
          />
          <meta property="og:type" content="article" />
          <meta property="og:url" content="https://simpletrips.com/" />
          <meta
            property="og:image"
            content="https://simpletrips.com/assets/images/meta.png"
          />
          <meta
            property="og:description"
            content="We take the pain out of researching, planning, and booking travel. We're real people with super powers thanks to modern technology and artificial intelligence. "
          />
        </Helmet>

        <div className="wrapper splash">
          <h1 className="lead">Simple Trips</h1>
          <h2 className="sublead">
            We make travel easy. No gimmicks. That's it.
          </h2>
          <div className="buttons">
            <Link to="/business-travel" className="button primary">
              For Business
            </Link>
            <div className="buttonbreak" />
            <Link to="/personal-travel" className="button alternate">
              For Personal
            </Link>
          </div>
        </div>

        <div className="wrapper about center">
          <br />
          <br />
          <p>
            We take the pain out of researching, planning, and booking travel.
            We're real people with super powers thanks to modern technology and
            artificial intelligence. We partner with you to help plan and book
            your travel experience. We've got your back from start to finish,
            whether you're starting from scratch or need help filling in the
            gaps.
          </p>
          <br />
        </div>

        <BlogRecent />

        <Footer />
      </div>
    );
  }
}

export default Landing;
