import React from "react";
import axios from "axios";

class HotelDetailEAN extends React.Component {
  state = {
    hotel: null
  };

  componentWillMount() {
    if (this.props.hotel.idEan) {
      this.fetchHotel(this.props.hotel.idEan);
    }
  }

  fetchHotel = id => {
    const hotelUrl =
      "https://us-central1-simple-trips.cloudfunctions.net/eanHotelDetail?id=" +
      id;
    console.log(hotelUrl);
    axios.get(hotelUrl).then(result => {
      this.setState({ hotel: result.data.data.HotelInformationResponse });
      console.log(result.data.data.HotelInformationResponse);
    });
  };

  render() {
    return (
      <div className="databucket eandata">
        {this.state.hotel == null ? (
          "loading"
        ) : (
          <div>
            <h4>EAN Description</h4>
            <div
              className="detail"
              id="property-description"
              dangerouslySetInnerHTML={{
                __html: this.state.hotel.HotelDetails.propertyDescription
              }}
            />
            <p className="detail">
              Trip Advisor Rating <br />
              <img
                src={this.state.hotel.HotelSummary.tripAdvisorRatingUrl}
                alt="Rating"
              />
            </p>

            <h4>EAN Amenities</h4>
            <ul>
              {this.state.hotel.PropertyAmenities.PropertyAmenity.map(
                (amenity, index) => {
                  return <li key={index}>{amenity.amenity}</li>;
                }
              )}
            </ul>

            <h4>EAN Room Types</h4>
            {this.state.hotel.RoomTypes.RoomType.map((roomType, index) => {
              return (
                <div key={index}>
                  <p>{roomType.description}</p>
                  <div
                    className="detail"
                    id="room-type-description"
                    dangerouslySetInnerHTML={{
                      __html: roomType.descriptionLong
                    }}
                  />
                </div>
              );
            })}

            <h4>EAN Images</h4>
            {this.state.hotel.HotelImages.HotelImage.map((image, index) => {
              return (
                <div key={index}>
                  <div
                    className="hotel-image"
                    style={{
                      backgroundImage: "url(" + image.highResolutionUrl + ")"
                    }}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

export default HotelDetailEAN;
