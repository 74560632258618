import React from "react";
import axios from "axios";
import HotelExpertReviewItem from "./HotelExpertReviewItem";
import { Link } from "react-router-dom";

class HotelExpertReviews extends React.Component {
  state = {
    reviews: [],
    attributionLink: "https://tripexpert.com/"
  };

  componentWillMount() {
    if (this.props.hotel.idTripExpert) {
      this.fetchDetailedInfo(this.props.hotel.idTripExpert);
    }
  }

  fetchDetailedInfo = id => {
    var hotelUrl =
      "https://us-central1-simple-trips.cloudfunctions.net/tripExpertVenueDetails" +
      "?api_key=50295020f3bb8aeaf70eabe370603e4c" +
      "&venue_id=" +
      id;

    axios.get(hotelUrl).then(result => {
      var reviews = result.data.response.venues[0].reviews;
      reviews = reviews.slice(0, 3); // limit to 3 reviews

      var link =
        "https://tripexpert.com/" + result.data.response.venues[0].path;
      this.setState({ reviews: reviews, attributionLink: link });
    });
  };

  render() {
    return (
      <div className="expertreviews">
        <div>
          {this.state.reviews.map((review, index) => {
            return <HotelExpertReviewItem key={index} review={review} />;
          })}
        </div>
        <div className="clear" />
        <div className="attribution">
          <p className="detail">
            <Link to={this.state.attributionLink}>
              <img
                src="/assets/images/powered-by-tripexpert.png"
                width="100"
                alt="Trip Expert Logo"
                className="te-attribution"
              />
            </Link>
          </p>
          <p className="detail">
            <Link to={this.state.attributionLink} target="_blank">
              Read more reviews
            </Link>
          </p>
        </div>
      </div>
    );
  }
}

export default HotelExpertReviews;
