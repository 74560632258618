import React from "react";
import Navbar from "./Navbar";

class AllHotels extends React.Component {
  render() {
    return (
      <div className="management">
        <Navbar />
        <div className="mainpanel">
          <h3>Hotels</h3>
        </div>
      </div>
    );
  }
}

export default AllHotels;
