import React from "react";
import axios from "axios";
import { ThreeBounce } from "better-react-spinkit";

class TripExpertCountryList extends React.Component {
  state = {
    countries: [],
    loading: false,
    showResults: false
  };

  fetchCountryList = () => {
    this.setState({ loading: true });
    const url =
      "https://us-central1-simple-trips.cloudfunctions.net/tripExpertCountries?api_key=50295020f3bb8aeaf70eabe370603e4c" +
      "&order_by=priority";
    console.log(url);
    axios.get(url).then(result => {
      console.log(result);
      this.setState({
        countries: result.data.response.countries,
        loading: false
      });
    });
  };

  render() {
    return this.state.loading === true ? (
      <ThreeBounce size={15} color="#396af1" />
    ) : (
      <div className="tripexpertdestinationlist">
        {this.state.showResults ? (
          <div>
            {Object.keys(this.state.countries).map(key => (
              <div>
                <p>
                  {this.state.countries[key].name} -{" "}
                  {this.state.countries[key].id}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <button
            className="general"
            onClick={() => {
              this.fetchCountryList();
              this.setState({ showResults: true });
            }}
          >
            Show Trip Expert Countries
          </button>
        )}
      </div>
    );
  }
}

export default TripExpertCountryList;
