import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import base, { firebaseApp } from "../base";
import Analytics from "react-router-ga";

// Generic Pages
import NotFound from "./Static/NotFound";
import Loading from "./Static/Loading";

// Dashboard Pages
import Dashboard from "./Dashboard/Dashboard";
import Settings from "./Dashboard/Settings";

// Management Pages
import Manage from "./Management/Manage";
import AddHotel from "./Management/AddHotel";
import AddDestination from "./Management/AddDestination";
import AllHotels from "./Management/AllHotels";
import AllDestinations from "./Management/AllDestinations";
import DestinationDetail from "./Management/DestinationDetail";
import HotelDetail from "./Management/HotelDetail";
import QuickInfo from "./Management/QuickInfo";
import HotelSearchEAN from "./Management/HotelSearchEAN";

// Public Dynamic Pages
import Hotel from "./Hotel";
import Destination from "./Destination";
import RequestNewPage from "./Dashboard/RequestNewPage";
import RequestViewPage from "./Dashboard/RequestViewPage";

// Authentication
import Recovery from "./Auth/Recovery";
import Login from "./Auth/Login";
import CreateAccount from "./Auth/CreateAccount";

// Content Pages
import Landing from "./ContentPages/Landing";
import LegalPrivacyPolicy from "./ContentPages/LegalPrivacyPolicy";
import LegalTerms from "./ContentPages/LegalTerms";
import About from "./ContentPages/About";
import Pricing from "./ContentPages/Pricing";
import Recommended from "./ContentPages/Recommended";
import PersonalTravel from "./ContentPages/PersonalTravel";
import BusinessTravel from "./ContentPages/BusinessTravel";
import BusinessDemo from "./ContentPages/BusinessDemo";
import BusinessDemoConfirmation from "./ContentPages/BusinessDemoConfirmation";
import PersonalTravelStart from "./ContentPages/PersonalTravelStart";
import Help from "./ContentPages/Help";
import FAQ from "./ContentPages/FAQ";
import ContactUs from "./ContentPages/ContactUs";
import FreeConsultation from "./ContentPages/FreeConsultation";
import FreeConsultationConfirmation from "./ContentPages/FreeConsultationConfirmation";

// Font Awesome Library
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faChartLine,
  faDollarSign,
  faCar,
  faExclamation,
  faDotCircle
} from "@fortawesome/free-solid-svg-icons";
library.add(fab, faChartLine, faDollarSign, faCar, faExclamation, faDotCircle);

// Router itself
class Router extends React.Component {
  state = {
    authed: false,
    loading: true,
    admin: false
  };

  componentDidMount() {
    firebaseApp.auth().onAuthStateChanged(user => {
      if (user) {
        const userData = { email: user.email, id: user.uid };

        // helps with debug
        //console.log(user.uid);

        base.updateDoc("users/" + user.uid, userData).catch(error => {
          console.log(error);
          if (error.code === "not-found") {
            base.addToCollection("users", userData, user.uid).catch(error => {
              console.log(error);
            });
          }
        });

        base
          .get("config/admins", {
            context: this
          })
          .then(data => {
            const admin = data[user.uid];
            this.setState({ authed: true, loading: false, admin: admin });
          })
          .catch(err => {
            //handle error
            console.log(err);
          });
      } else {
        this.setState({ authed: false, loading: false });
      }
    });
  }

  render() {
    return this.state.loading === true ? (
      <Loading />
    ) : (
      <div>
        <BrowserRouter>
          <Analytics id="UA-114640999-6" debug>
            <Switch>
              {/* Regular Routes - static pages */}
              <Route exact path="/" component={Landing} />
              <Route exact path="/index.html" component={Landing} />
              <Route
                exact
                path="/legal/privacy"
                component={LegalPrivacyPolicy}
              />
              <Route exact path="/legal/terms" component={LegalTerms} />
              <Route exact path="/about-us" component={About} />
              <Route exact path="/help" component={Help} />
              <Route exact path="/faq" component={FAQ} />
              <Route exact path="/contact-us" component={ContactUs} />
              <Route exact path="/recommended" component={Recommended} />
              <Route exact path="/pricing" component={Pricing} />
              <Route exact path="/personal-travel" component={PersonalTravel} />
              <Route
                exact
                path="/free-consultation"
                component={FreeConsultation}
              />
              <Route
                exact
                path="/free-consultation/confirmation"
                component={FreeConsultationConfirmation}
              />
              <Route
                exact
                path="/personal-travel/start"
                component={PersonalTravelStart}
              />
              <Route exact path="/business-travel" component={BusinessTravel} />
              <Route
                exact
                path="/business-travel/demo"
                component={BusinessDemo}
              />
              <Route
                exact
                path="/business-travel/demo-confirmation"
                component={BusinessDemoConfirmation}
              />

              {/* Regular Routes - dynamic pages */}
              <Route exact path="/hotels/:slug" component={Hotel} />
              <Route exact path="/destinations/:slug" component={Destination} />

              {/* Privage Routes - require a user to have an account */}
              <PrivateRoute
                authed={this.state.authed}
                exact
                path="/dashboard"
                component={Dashboard}
              />
              <PrivateRoute
                authed={this.state.authed}
                exact
                path="/requests/new"
                component={RequestNewPage}
              />
              <PrivateRoute
                authed={this.state.authed}
                exact
                path="/r/:requestid"
                component={RequestViewPage}
              />
              <PrivateRoute
                authed={this.state.authed}
                exact
                path="/settings"
                component={Settings}
              />

              {/* Public Routes - used for any page that a logged-in user can't see (signup, login) */}
              <PublicRoute
                authed={this.state.authed}
                exact
                path="/login"
                component={Login}
              />
              <PublicRoute
                authed={this.state.authed}
                exact
                path="/sign-up"
                component={CreateAccount}
              />
              <PublicRoute
                authed={this.state.authed}
                exact
                path="/account-recovery"
                component={Recovery}
              />

              {/* Admin Routes - require user to be an admin (not really) */}
              <AdminRoute
                admin={this.state.admin}
                exact
                path="/manage"
                component={Manage}
              />
              <AdminRoute
                admin={this.state.admin}
                exact
                path="/manage/info"
                component={QuickInfo}
              />
              <AdminRoute
                admin={this.state.admin}
                exact
                path="/manage/hotels/new"
                component={AddHotel}
              />
              <AdminRoute
                admin={this.state.admin}
                exact
                path="/manage/hotels/ean/search"
                component={HotelSearchEAN}
              />
              <AdminRoute
                admin={this.state.admin}
                exact
                path="/manage/destinations/new"
                component={AddDestination}
              />
              <AdminRoute
                admin={this.state.admin}
                exact
                path="/manage/hotels"
                component={AllHotels}
              />
              <AdminRoute
                admin={this.state.admin}
                exact
                path="/manage/destinations"
                component={AllDestinations}
              />
              <AdminRoute
                admin={this.state.admin}
                exact
                path="/manage/destinations/:slug"
                component={DestinationDetail}
              />
              <AdminRoute
                admin={this.state.admin}
                exact
                path="/manage/hotels/:slug"
                component={HotelDetail}
              />

              {/* 404 page */}
              <Route component={NotFound} />
            </Switch>
          </Analytics>
        </BrowserRouter>
      </div>
    );
  }
}

function PrivateRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        authed === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

function PublicRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        authed === false ? (
          <Component {...props} />
        ) : (
          <Redirect to="/dashboard" />
        )
      }
    />
  );
}

function AdminRoute({ component: Component, admin, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        admin === true ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
}

export default Router;
