import React from "react";
import Navbar from "./Navbar";
import DestinationList from "./DestinationList";

class AllDestinations extends React.Component {
  render() {
    return (
      <div className="management">
        <Navbar />
        <div className="mainpanel">
          <h3>Destinations</h3>
          <DestinationList />
        </div>
      </div>
    );
  }
}

export default AllDestinations;
