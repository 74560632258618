import React from "react";
import { Helmet } from "react-helmet";

import Footer from "../Static/Footer";
import Header from "../Static/Header";

class BusinessDemoConfirmation extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="pageframe business demo-confirmation">
        <Header />
        <Helmet>
          {/* Generic  */}
          <title>Your demo is confirmed - Simple Trips for Business</title>
          <meta
            name="description"
            content="Get a demo to learn how to save time and money using our Managed Travel solution, which is customized to your individual business needs."
          />
        </Helmet>

        <div className="wrapper">
          <div className="pagetitlecontainer">
            <h1>
              Your appointment has been confirmed. We're looking forward to it!
            </h1>
          </div>

          <div className="clear" />
        </div>

        <Footer />
      </div>
    );
  }
}

export default BusinessDemoConfirmation;
