import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Footer from "../Static/Footer";
import Header from "../Static/Header";
import Callout from "./Elements/Callout";
import PageTitle from "./Elements/PageTitle";

class About extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="pageframe faq overview">
        <Header />
        <Helmet>
          {/* Generic  */}
          <title>About Us - Simple Trips</title>
          <meta
            name="description"
            content="About Simple Trips - learn about our company."
          />

          {/* Twitter */}
          <meta name="twitter:card" value="About Simple Trips" />

          {/* Open Graph */}
          <meta property="og:title" content="About Simple Trips" />
          <meta property="og:type" content="article" />
          <meta
            property="og:url"
            content="https://simpletrips.com/business-travel"
          />
          <meta
            property="og:image"
            content="https://simpletrips.com/assets/images/meta.png"
          />
          <meta property="og:description" content="About Simple Trips" />
        </Helmet>

        <PageTitle title="About Us" subtitle="Simple Trips" />

        <Callout>
          <p>
            Simple Trips was founded on one straightforward mission: travel
            should be simple for everyone. We started by providing our custom
            travel services to a select list of early customers, and we expanded
            our offering for personal travel services with a public release
            shortly after. Additionally, we launched Simple Trips for Business
            to create an offering focused on solving the needs of small and
            medium businesses.
          </p>
        </Callout>
        <Callout
          title="Finding the best travel deal shouldn't take hours"
          description="We focus on making it easy and painless to plan and book trips. That's it. We are leveraging technology and intelligence to empower our agents to help you along the way. While we can help with obvious tasks such as booking a flight or hotel, we go above and beyond by offering a concierge-style service."
        />

        <Callout
          title="Planning travel should be fun and engaging - not slow and frustrating."
          description="Any request you may have — be it trip research, planning activities, making dinner reservations, finding flights using miles or points, and more — we are here to help."
        />

        <Callout
          title="Get treated like a friend. No games. No gimmicks."
          description="Our goal, first and foremost, is to provide a high-value service with the most honest advice we can. We treat you like we treat our closest friends. We get to know you and your travel preferences, and we strive to offer personal service every time."
        />

        <Callout
          title="Personal service and support for every customer."
          description="We proudly give everyone a direct phone number for help and support. We want to have conversations with each and every one of our customers, for any reason. Every time you need help, want to book a new trip, or anything else, you get to talk to a real person. We're available via email, phone, text message, or online chat."
        />

        <Callout
          title="We've got your back - every time."
          description="Our agents are experts, each with years of experience booking travel professionally. We leverage that experience to provide valuable tips and insider knowledge when available. We're also there for you before, during, and after your trip."
        />

        <Callout title="Learn more">
          <p>
            We offer services targeted at business or personal travelers. Select
            the best fit for you to learn more.
          </p>
          <Link to="/business-travel/" className="button regular">
            Business Travel
          </Link>
          <br />
          <br />
          <br />
          <br />
          <Link to="/personal-travel/" className="button regular">
            Personal Travel
          </Link>
        </Callout>

        <Footer />
      </div>
    );
  }
}

export default About;
