import React, { Component } from "react";
import { ThreeBounce } from "better-react-spinkit";

class LoadingSimple extends Component {
  render() {
    return (
      <div>
        <ThreeBounce size={15} color="#396af1" />
      </div>
    );
  }
}

export default LoadingSimple;
