import React from "react";
import { Link } from "react-router-dom";
import base, { firebaseApp } from "../../base";

class AdminJump extends React.Component {
  state = {
    admin: false
  };
  componentDidMount() {
    base
      .get("config/admins", {
        context: this
      })
      .then(data => {
        console.log(data);
        const uid = firebaseApp.auth().currentUser.uid;
        const admin = data[uid];
        this.setState({ admin: admin });
      })
      .catch(err => {
        //handle error
        console.log(err);
      });
  }
  render() {
    return this.state.admin ? (
      <div className="admin-jump">
        <Link className="button" to="/manage" target="_blank">
          Management Panel
        </Link>
        <br /> <br />
      </div>
    ) : (
      ""
    );
  }
}

export default AdminJump;
