import React, { Component } from "react";
import { Link } from "react-router-dom";
class TopBar extends Component {
  render() {
    return (
      <div>
        <div className="bar">
          <div className="container">
            <div className="branding">
              <Link to="/dashboard">
                <img
                  src="/assets/images/logo.png"
                  width="24"
                  alt="Simple Trips Logo"
                />
              </Link>
            </div>

            <ul className="nav">
              <li className="nav-item">
                <Link to="/dashboard" className="nav-link dashboard">
                  <i className="fas fa-home" />
                </Link>
              </li>
              {/* <li className="nav-item">
              <Link to="/profile" className="nav-link profile">
                <i className="fas fa-user" />
              </Link>
            </li> */}
              <li className="nav-item">
                <Link to="/settings" className="nav-link settings">
                  <i className="fas fa-cog" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="barspacer" />
      </div>
    );
  }
}

export default TopBar;
