import React from "react";
import Navbar from "./Navbar";
import TripExpertDestinationList from "./TripExpertDestinationList";
import TripExpertCountryList from "./TripExpertCountryList";
import Slug from "slug";
import base from "../../base";

class AddDestination extends React.Component {
  createDestination = event => {
    event.preventDefault();
    const destinationName = this.destinationNameRef.value;
    const latitude = this.latitudeRef.value;
    const longitude = this.longitudeRef.value;
    const tripExpertId = this.tripExpertIdRef.value;
    const slug = this.slugRef.value;
    const timestamp = new Date().getTime();

    if (
      !destinationName.length ||
      !latitude.length ||
      !longitude.length ||
      !slug.length
    ) {
      return;
    }

    const destinationData = {
      name: destinationName,
      latitude: latitude,
      longitude: longitude,
      tripExpertId: tripExpertId ? tripExpertId : "",
      slug: slug,
      lastActivity: timestamp,
      createdAt: timestamp
    };

    // Create the destination in our destinations root collection
    base
      .addToCollection("destinations", destinationData)
      .then(destinationDocument => {
        console.log(destinationDocument);
        // Redirect to the index
        this.props.history.push("/manage");
      });
  };

  selectFromTripExpert = selected => {
    this.destinationNameRef.value = selected.name;
    this.latitudeRef.value = selected.latitude;
    this.longitudeRef.value = selected.longitude;
    this.tripExpertIdRef.value = selected.id;
    this.slugRef.value = Slug(selected.name).toLowerCase();
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <div className="management">
        <Navbar />
        <div className="mainpanel">
          <h3>Add Destination</h3>
          <div className="addform">
            <form action="" className="login" onSubmit={this.createDestination}>
              <label htmlFor="destinationName" className="descriptor">
                Name{" "}
              </label>{" "}
              <br />
              <input
                ref={destinationNameRef =>
                  (this.destinationNameRef = destinationNameRef)
                }
                name="destinationName"
                placeholder="Name"
                type="text"
                className="detail"
              />
              <br />
              <label htmlFor="latitude" className="descriptor">
                Latitude{" "}
              </label>{" "}
              <br />
              <input
                ref={latitudeRef => (this.latitudeRef = latitudeRef)}
                name="latitude"
                placeholder="Latitude"
                type="text"
                className="detail"
              />
              <br />
              <label htmlFor="longitude" className="descriptor">
                Longitude{" "}
              </label>{" "}
              <br />
              <input
                ref={longitudeRef => (this.longitudeRef = longitudeRef)}
                name="longitude"
                placeholder="Longitude"
                type="text"
                className="detail"
              />
              <br />
              <label htmlFor="tripExpertIdRef" className="descriptor">
                Trip Expert ID{" "}
              </label>{" "}
              <br />
              <input
                ref={tripExpertIdRef =>
                  (this.tripExpertIdRef = tripExpertIdRef)
                }
                name="tripExpertId"
                placeholder="Trip Expert ID"
                type="text"
                className="detail"
              />
              <br />
              <label htmlFor="slug" className="descriptor">
                URL Slug{" "}
              </label>{" "}
              <br />
              <input
                ref={slugRef => (this.slugRef = slugRef)}
                name="slug"
                placeholder="Slug"
                type="text"
                className="detail"
              />
              <br />
              <button type="submit" className="add">
                Add Destination
              </button>
            </form>
          </div>

          <br />
          <h4>From TripExpert</h4>
          <br />
          <TripExpertDestinationList selectFrom={this.selectFromTripExpert} />

          <TripExpertCountryList />
        </div>
      </div>
    );
  }
}

export default AddDestination;
