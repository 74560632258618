import React from "react";
import { forgotPassword } from "../../helpers/auth";

import Footer from "../Static/Footer";
import Header from "../Static/Header";
import LoadingSimple from "../Static/LoadingSimple";

class Recovery extends React.Component {
  state = {
    loading: false,
    errorMessage: null,
    successMessage: null
  };

  componentDidMount() {
    // window.ga("send", "event", "foo");
    window.scrollTo(0, 0);
  }

  performForgot = event => {
    event.preventDefault();
    this.setState({ loading: true });
    const email = this.emailRef.value;
    forgotPassword(email)
      .then(() => {
        this.setState({
          successMessage: true,
          errorMessage: null,
          loading: false
        });
      })
      .catch(error => {
        // console.log(error);
        var simpleMessage = "";
        switch (error.code) {
          case "auth/invalid-email": {
            simpleMessage = "Please enter a valid email address.";
            break;
          }
          case "auth/user-not-found": {
            simpleMessage =
              "The email address entered does not have an account.";
            break;
          }
          default: {
            simpleMessage = "There was a problem. Please try again.";
          }
        }
        this.setState({ errorMessage: simpleMessage, loading: false });
      });
  };

  render() {
    return (
      <div className="pageframe accountrecovery">
        <Header />

        <div className="authbox">
          <div className="title">Recover your account</div>
          {this.state.loading ? (
            <div className="spacing">
              <LoadingSimple />
            </div>
          ) : (
            <div>
              {this.state.errorMessage ? (
                <div className="errorbox">
                  <i className="fas fa-exclamation-circle erroricon" />
                  <p className="errormessage">{this.state.errorMessage}</p>
                  <div className="clear" />
                </div>
              ) : (
                ""
              )}
              {this.state.successMessage ? (
                <div className="prompt">
                  Please check your email, we just sent instructions on how to
                  set your password.
                </div>
              ) : (
                <div>
                  <div className="prompt">
                    Enter your email address and we'll send you instructions to
                    update your password.
                  </div>

                  <form
                    action=""
                    className="signup"
                    onSubmit={this.performForgot}
                  >
                    <input
                      className="login"
                      ref={emailRef => (this.emailRef = emailRef)}
                      name="email"
                      placeholder="Email address"
                      type="email"
                    />

                    <button className="button login traditional" type="submit">
                      Continue
                    </button>
                  </form>
                </div>
              )}
            </div>
          )}
        </div>

        <Footer />
      </div>
    );
  }
}

export default Recovery;
