import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";

class HotelExpertReviewItem extends React.Component {
  state = {
    publications: [],
    publicationIcon: ""
  };

  componentWillMount() {
    this.fetchPublications();
  }

  fetchPublications = () => {
    var hotelUrl =
      "https://us-central1-simple-trips.cloudfunctions.net/tripExpertPublications?api_key=50295020f3bb8aeaf70eabe370603e4c";
    axios.get(hotelUrl).then(result => {
      const publications = result.data.response.publications;
      publications.map((pub, index) => {
        if (pub.id === this.props.review.publication_id) {
          this.setState({ publicationIcon: pub.icon });
        }
        return "";
      });
    });
  };

  render() {
    return (
      <div className="reviewsingle">
        <div className="icon">
          <img
            src={this.state.publicationIcon}
            width={40}
            alt={this.props.review.publication_name}
          />{" "}
        </div>
        <div className="content">
          <div className="publicationname">
            {this.props.review.publication_name}
          </div>
          <div className="reviewextract">{this.props.review.extract}</div>
          {this.props.review.source_url ? (
            <div className="reviewlink">
              <Link to={this.props.review.source_url} target="_blank">
                read more
              </Link>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="clear" />
      </div>
    );
  }
}

export default HotelExpertReviewItem;
