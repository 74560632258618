import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class FooterSocialRow extends React.Component {
  render() {
    return (
      <div className="footersocialrow">
        <p>
          <a
            href="http://facebook.com/SimpleTripsTravel"
            className="footer_social_icon facebook"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={["fab", "facebook"]} size="1x" />{" "}
          </a>
          <a
            href="http://m.me/SimpleTripsTravel"
            className="footer_social_icon facebookmessenger"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={["fab", "facebook-messenger"]} size="1x" />{" "}
          </a>
          <a
            href="http://instagram.com/SimpleTripsTravel"
            className="footer_social_icon instagram"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={["fab", "instagram"]} size="1x" />{" "}
          </a>
          <a
            href="https://www.linkedin.com/company/simple-trips/"
            className="footer_social_icon linkedin"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={["fab", "linkedin"]} size="1x" />
          </a>
          <a
            href="http://twitter.com/askSimpleTrips"
            className="footer_social_icon twitter"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={["fab", "twitter"]} size="1x" />
          </a>

          <a
            href="https://www.youtube.com/channel/UCJj-gTbtK_kWVzuHmSHscFg"
            className="footer_social_icon youtube"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={["fab", "youtube"]} size="1x" />
          </a>
          <a
            href="https://medium.com/simple-trips"
            className="footer_social_icon medium"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={["fab", "medium-m"]} size="1x" />
          </a>
        </p>
      </div>
    );
  }
}

export default FooterSocialRow;
