import React from "react";
import Navbar from "./Navbar";
import axios from "axios";

import HotelSearchEANItem from "./HotelSearchEANItem";
import DestinationPickList from "./DestinationPickList";
import LoadingSimple from "../Static/LoadingSimple";

class HotelSearchEAN extends React.Component {
  state = {
    hotels: [],
    destination: null,
    loading: false
  };

  clearDestination = () => {
    this.setState({ destination: null, hotels: [], count: null });
  };

  pickDestination = destination => {
    this.setState({ destination: destination });
    this.fetchHotels(destination);
  };

  fetchHotels = destination => {
    this.setState({ loading: true });

    const url =
      "https://us-central1-simple-trips.cloudfunctions.net/eanHotelSearchRadius?" +
      "&latitude=" +
      destination.latitude +
      "&longitude=" +
      destination.longitude;
    axios.get(url).then(results => {
      this.setState({
        loading: false,
        hotels: results.data.results.HotelListResponse.HotelList.HotelSummary,
        count:
          results.data.results.HotelListResponse.HotelList[
            "@activePropertyCount"
          ]
      });
    });
  };

  render() {
    return (
      <div className="management">
        <Navbar />
        <div className="mainpanel">
          <div className="hotelsearchean">
            {this.state.destination ? (
              <div>
                <p className="destinationname">
                  Destination: {this.state.destination.name}{" "}
                  <button
                    className="choose"
                    onClick={() => {
                      this.clearDestination();
                    }}
                  >
                    change
                  </button>
                </p>
              </div>
            ) : (
              <div>
                <br />
                <h4>Choose Destination</h4>
                <DestinationPickList pickerCallback={this.pickDestination} />
                <br />
                <br />
              </div>
            )}
            <div>
              {this.state.loading ? (
                <LoadingSimple />
              ) : (
                <div>
                  {Object.keys(this.state.hotels).map(key => (
                    <HotelSearchEANItem
                      hotel={this.state.hotels[key]}
                      key={key}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HotelSearchEAN;
