import React, { Component } from "react";
import { Link } from "react-router-dom";
import FooterSocialRow from "./FooterSocialRow";

class Footer extends Component {
  render() {
    return (
      <footer id="footer">
        <div className="footercols wrapper">
          <div className="col branding">
            <p className="colheader branding">Simple Trips</p>
            <p className="description">
              We make travel easy with the perfect blend of expert people and
              artificial intelligence. Flights, hotels, cars, and more. No
              gimmicks.
            </p>
          </div>

          <div className="col">
            <p className="colheader">Learn More</p>
            <p className="colitem">
              <Link to="/business-travel">Simple Trips for Business</Link>
            </p>
            <p className="colitem">
              <Link to="/personal-travel">Personal Travel</Link>
            </p>
            <p className="colitem">
              <Link to="/free-consultation">Free Consultation</Link>
            </p>
            {/* <p className="colitem">
              <Link to="/">Travel Concierge</Link>
            </p>
            <p className="colitem">
              <Link to="/">Package Trips</Link>
            </p>
            <p className="colitem">
              <Link to="/">Top Destinations</Link>
            </p> */}
          </div>

          <div className="col">
            <p className="colheader">Company</p>
            <p className="colitem">
              <a href="https://blog.simpletrips.com/">Visit our Blog</a>
            </p>
            <p className="colitem">
              <Link to="/about-us">About Us</Link>
            </p>
            <p className="colitem">
              <Link to="/legal/terms">Terms and Conditions</Link>
            </p>
            <p className="colitem">
              <Link to="/legal/privacy">Privacy Policy</Link>
            </p>
            {/* <p className="colitem">
              <Link to="/">Careers</Link>
            </p>
            <p className="colitem">
              <Link to="/">Press Kit</Link>
            </p> */}
          </div>

          <div className="col">
            <p className="colheader">Support</p>
            <p className="colitem">
              <Link to="/help">Help Center</Link>
            </p>
            <p className="colitem">
              <Link to="/faq">FAQ</Link>
            </p>
            <p className="colitem">
              <Link to="/contact-us">Contact Us</Link>
            </p>
          </div>

          <div className="clear" />

          <FooterSocialRow />
          <div className="clear" />

          <div className="copyright colitem">
            <p>&copy; 2019 Simple Trips, Inc.</p>
          </div>
          <div className="clear" />
        </div>
      </footer>
    );
  }
}

export default Footer;
