import React from "react";
import Footer from "../Static/Footer";
import Header from "../Static/Header";
import Callout from "./Elements/Callout";
import Feature from "./Elements/Feature";
import PageTitle from "./Elements/PageTitle";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

class BusinessTravel extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="pageframe business overview">
        <Header />
        <Helmet>
          {/* Generic  */}
          <title>
            Simple Trips for Business - Easy Managed Travel. Guaranteed.
          </title>
          <meta
            name="description"
            content="Save time and money using our Managed Travel solution, which is customized to your individual business needs."
          />

          {/* Twitter */}
          <meta
            name="twitter:card"
            value="Save time and money using our Managed Travel solution, which is customized to your individual business needs."
          />

          {/* Open Graph */}
          <meta
            property="og:title"
            content="Simple Trips for Business - Easy Managed Travel. Guaranteed."
          />
          <meta property="og:type" content="article" />
          <meta
            property="og:url"
            content="https://simpletrips.com/business-travel"
          />
          <meta
            property="og:image"
            content="https://simpletrips.com/assets/images/meta.png"
          />
          <meta
            property="og:description"
            content="Save time and money using our Managed Travel solution, which is customized to your individual business needs."
          />
        </Helmet>

        <PageTitle
          title="Simple Trips for Business"
          subtitle="Easy Managed Travel. Guaranteed."
        />

        <Callout
          description="Simple Trips offers managed travel services. Do you have a business
              with employees that travel for work? We have the perfect solution
              for you."
        />

        <Feature
          title="Managed Travel"
          description="Save time and money using our Managed Travel solution, which is customized to your individual business needs."
          position="right"
          graphic="/assets/svg/undraw.workchat.svg"
        />

        <Feature
          title="Flights, Hotels, and more"
          description="Not only can we help your business with flights and hotels, we can also assist with making restaurant reservations and meeting locations."
          position="left"
          graphic="/assets/svg/undraw.airport.svg"
        />

        <Feature
          title="Custom Reporting"
          description="Every business has unique needs. Want individual travel expenditure reports, monthly summaries, or something different? We've got your back."
          position="right"
          graphic="/assets/svg/undraw.analytics.svg"
        />

        <Feature
          title="Save Money"
          description="We implement custom booking policies to match your business. Additionally, we utilize best-in-class tools to find you the best rates available."
          position="left"
          graphic="/assets/svg/undraw.segmentation.svg"
        />

        <Callout
          title="Free Consultation"
          description="Does your business spend money on travel? We can help you save
              time and money, guaranteed. To get started, simply book an
              appointment for a free consultation."
        >
          <Link to="/free-consultation" className="button regular">
            Book a Demo
          </Link>
        </Callout>

        <Footer />
      </div>
    );
  }
}

export default BusinessTravel;
